import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

const styles = {
  categories: {
    display: "flex",
    "& li": {
      cursor: "default",
    }
  },
  categoryName: {
    padding: "8px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.07)",
    },
    "&.active": {
      backgroundColor: "rgba(0, 0, 0, 0.07)",
    },
  },
  categoryLinksWrap: {
    flex: "1",
    marginTop: "4px",
  },
  categoryLinks: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    marginLeft: "20px",
    '& li': {
      padding: "4px",
    }
  },
};


const CategoryNavigation = ({ categories, classes }) => {

  const categoriesObject = categories.reduce((acc, category) => {
    acc[category.data.name] = category.children ? category.children.map(child => child.data.name) : [];

    return acc;
  }, {});


  const categoryButtonName = Object.keys(categoriesObject);

  const [activeCategory, setActiveCategory] = useState(categoryButtonName[0]);


  const handleMouseEnter = (categoryName) => {
    setActiveCategory(categoryName);
  }

  // const handleMouseLeave = () => {
  //   setActiveCategory(categoryButtonName[0]);
  // }

  //console.log(categoriesObject)

  //console.log("categories: ", categories)

  return (
    <div className={classes.categories}>
      <ul>
        {categoryButtonName.map((buttonName, index) => (
          <li
            key={index}
            className={`${classes.categoryName} ${activeCategory === buttonName ? "active" : ""}`}
            onMouseEnter={() => {
              handleMouseEnter(buttonName)
            }}
          // onMouseLeave={handleMouseLeave}
          >
            {buttonName}
          </li>
        ))}
      </ul>
      <div className={classes.categoryLinksWrap}>
        {activeCategory && categoriesObject[activeCategory].length > 0 && (
          <ul className={classes.categoryLinks}>
            {categoriesObject[activeCategory].map((subCategory, i) => (
              <li key={i}>
                <Link to={'/pricelist?category=' + subCategory}>{subCategory}</Link>
              </li>
            ))}
          </ul>
        )}
      </div>



    </div>
  )

}

export default withStyles(styles)(CategoryNavigation);