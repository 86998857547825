import React, { Component } from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import ViewHeadline from "@material-ui/icons/ViewHeadline";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import Articles from "components/Articles/Articles.jsx";
import {EditArticleLightbox} from "components/Articles/Articles.jsx";
import {SendArticleLightbox} from "components/Articles/Articles.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Typography from '@material-ui/core/Typography';
import Catalog from "views/Catalog/Catalog.jsx";

// import { bugs, website, server } from "variables/general.jsx";
import { doAjax, doAjaxPost, getSettingValue, getSettingValueAsBoolean } from "variables/common.js";
import * as constants from "variables/constants.jsx";

import Moment from 'react-moment';


import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import i18next from 'i18next';

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

//const ReactHighcharts = require('react-highcharts');

window.Highcharts = Highcharts;

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.editArticleLBRef = React.createRef();
	    this.sendArticleLBRef = React.createRef();

    this.state = {
	    value: 0,
	    ordersCountData: [],
	    orderItemsCountData: [],
	    supplierClient: (localStorage.getItem('supplierClient') ? JSON.parse(localStorage.getItem('supplierClient')) : JSON.parse(localStorage.getItem('supplierUser')).value.supplierClient),
	    showCreditWidget: getSettingValueAsBoolean('DASHBOARD_SHOW_CREDIT_WIDGET', true),
	    news: [],
    	actions: []    	
    };
    
    this.state.articlesTabActiveId = 0;

  }

  componentWillMount = () => {
    this.getSupplierClient();
    this.loadWelcomeText();
    this.loadArticles(1);
    this.loadArticles(2);
	Highcharts.setOptions({
      lang: {
        resetZoom: this.props.i18n.t('resetZoom'),
        resetZoomTitle: this.props.i18n.t('resetZoomValue')
      }
		    });
  }
  
  loadArticles = (type) => {
  	  var user = JSON.parse(localStorage.getItem('user')).value;
	  
	  var that = this;
	  
	  var cb = function(data) { 
		
	  switch (type) {
        case 1:
        that.state.news = data;
        break;
        case 2:
        that.state.actions = data;
        break;
       }
	    
	  };
	  
	  var url = constants.apiBaseUrl + '/b2b/supplier/articles?key=' + constants.apiKey + '&supplierId=' + this.props.supplierId + "&principalId=" + user.id + "&limit=";
          url +=  (type === 1  ? getSettingValue("B2B_NEWS_LIMIT", "3")  : getSettingValue("B2B_ACTIONS_LIMIT", "3") );
          url +=  '&type=' + type + '&upd=' + new Date().getTime();
	  doAjax(url, cb, this.props.handleProgressBarClose);
  
  }
  
  loadWelcomeText = () => {
  
      var user = JSON.parse(localStorage.getItem('user')).value;
	
	  var cb = function(data) { 
	    
	    var welcomeDiv = document.getElementById('welcome-text');
	    if (welcomeDiv && data) {
	    	welcomeDiv.innerHTML = data.replace(/(?:\r\n|\r|\n)/g, '<br>');
	    	document.getElementById('welcome-block').style.display = 'block';
	    }
	    
	  };
	  
	  var url = constants.apiBaseUrl + '/b2b/supplier/setting/welcome_text?key=' + constants.apiKey + '&supplierId=' + this.props.supplierId + "&principalId=" + user.id + '&upd=' + new Date().getTime();
	  doAjax(url, cb, this.props.handleProgressBarClose);
  
  }
  
  getSupplierClient = () => {
  
  	if (this.state.supplierClient) {
  	
  	  	var user = JSON.parse(localStorage.getItem('user')).value;
  	
  		var url = constants.apiBaseUrl + '/b2b/supplier/client?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierClientId=" + this.state.supplierClient.id;

	    var that = this;
	
	    var cb = function(data) { 
	    
	    	that.setState({supplierClient: data}); localStorage.setItem('supplierClient', JSON.stringify(data));
	        
	        that.getOrdersCountData();
    		that.getOrderItemsCountData();
	    
	     };
	
	    this.props.handleProgressBarOpen();
	    doAjax(url, cb, this.props.handleProgressBarClose);
  	
  	} else {
  		
  		this.getOrdersCountData();
    	this.getOrderItemsCountData();
  		
  	}
  
  
  }

  getOrdersCountData = () => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;
  
    var url = constants.apiBaseUrl + '/b2b/supplier/orders-by-dates?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierId=" + this.props.supplierId;
    if (this.state.supplierClient) {
    	url += "&supplierClientId=" + this.state.supplierClient.id;	
    }
      
    // + (!this.props.isAdmin ? "&userId=" + this.props.userId : "");

    var that = this;

    var cb = function(data) { that.setState({ordersCountData: data}); };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose);
  }

  getOrderItemsCountData = () => {
   
    var user = JSON.parse(localStorage.getItem('user')).value;
  
    var url = constants.apiBaseUrl + '/b2b/supplier/order-items-by-dates?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierId=" + this.props.supplierId;
	if (this.state.supplierClient) {
    	url += "&supplierClientId=" + this.state.supplierClient.id;	
    }
	// + (!this.props.isAdmin ? "&userId=" + this.props.userId : "")

    var that = this;

    var cb = function(data) { that.setState({orderItemsCountData: data}); };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose);
  }

  getDates = list => {
    var dates = [];
    var formatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

    list.map(row => {
      var spl = row[0].split('.');
      dates.push(new Date(spl[0], spl[1] - 1, spl[2]).toLocaleDateString('ru-RU', formatOptions));
    });

    return dates;
  }

  getSeries = list => {
    var series = [{ data: []}];
    var formatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

    series[0].name = 'Кол-во';
    list.map(row => {
      series[0].data.push(parseInt(row[1]));
    });
    
    return series;
  }

  makeCountOptions = list => {
    return {
      chart: {
        zoomType: 'x',
        height: 300 ,
        animation: true
      },
      credits: {
        enabled: false
      },
      tooltip : {
        shared : true
      },
      title: '',
      dateRangeGrouping: {
        enabled: true
      },
      xAxis: {
        categories: this.getDates(list),
        labels: {
          rotation: -45
        },
        crosshair: {
          width: 1,
          color: '#c4c4c4'
        },
        type: "datetime"        
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      series: this.getSeries(list),
      exporting: {
        enabled: false
      }
    }
  }
  
  makeDebtChart = value => {
    return {
      chart: {
        zoomType: 'x',
        height: 300
      },
      credits: {
        enabled: false
      },
      tooltip : {
        shared : true
      },
      title: '',
      dateRangeGrouping: {
        enabled: true
      },
      xAxis: {
        labels: {
          rotation: -45
        },
        crosshair: {
          width: 1,
          color: '#c4c4c4'
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      //series: this.getSeries(value),
      exporting: {
        enabled: false
      }
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  getArticlesTabActiveId = (value) => {
    this.state.articlesTabActiveId = value;
  }
  
  render() {
    const { classes, i18n, isAdmin, isManager, handleProgressBarOpen, handleProgressBarClose } = this.props;
    
    console.log(this.editArticleLBRef);
    
    return (
      <div>
      	{window.location.href.indexOf('triovist') >= 0 ?
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Catalog
              i18n={i18next}
              handleProgressBarOpen={this.props.handleProgressBarOpen}
              handleProgressBarClose={this.props.handleProgressBarClose}
              handleShowSnackbar={this.props.handleShowSnackbar}
              classes={classes}
              supplierId={this.props.supplierId}
              isAdmin={isAdmin}
              isClientAdmin={this.state.isClientAdmin}
              isSupervisor={this.state.isSupervisor}
              isWidget='true'
            />
          </GridItem>
        </GridContainer> : ""
        }
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader className={classes.chart} color="gray">
                <h4 className={classes.cardTitle}>{i18n.t("ordersCountByDays")}</h4>
              </CardHeader>
              <CardBody>
                <HighchartsReact options={this.makeCountOptions(this.state.ordersCountData)} allowChartUpdate = { true } highcharts={Highcharts} constructorType={'chart'} />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader className={classes.chart} color="gray">
                <h4 className={classes.cardTitle}>{i18n.t("orderItemsCountByDays")}</h4>
              </CardHeader>
              <CardBody>
               <HighchartsReact options={this.makeCountOptions(this.state.orderItemsCountData)} allowChartUpdate = { true } highcharts={Highcharts} constructorType={'chart'} />
              </CardBody>
            </Card>
          </GridItem>
          {
         this.state.showCreditWidget && this.state.supplierClient && 
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader className={classes.chart} color="gray">
                <h4 className={classes.cardTitle}>{i18n.t("Информация о кредите")}</h4>
              </CardHeader>
              <CardBody>
              	<Typography variant="h4" style={{fontSize: "16px", lineHeight: "170%"}}>
                	Использованный кредитный лимит: <b fontWeight="fontWeightBold">
                	{
                		(this.state.supplierClient.debtLimit == null && this.state.supplierClient.debt) > 0 ? 100 : 
                			(this.state.supplierClient.debtLimit != null ? 
                				Math.round((this.state.supplierClient.debt + (this.state.supplierClient.debtReserved != null ? this.state.supplierClient.debtReserved : 0)) / this.state.supplierClient.debtLimit * 100) 
                				: '')
                		}
                	%</b>
                </Typography>
              	<Typography variant="h4" style={{fontSize: "16px", lineHeight: "170%"}}>
                	Кредитный лимит: <b fontWeight="fontWeightBold">{this.state.supplierClient.debtLimit}</b>
                </Typography>
              	<Typography variant="h4" style={{fontSize: "15px", lineHeight: "170%"}}>
                	{getSettingValue("B2B_WIDGET_CREDIT_DEBT_TITLE", "Общая сумма долга")}: <b fontWeight="fontWeightBold">{this.state.supplierClient.debt}</b>
                </Typography>
              	<Typography variant="h4" style={{fontSize: "15px", lineHeight: "170%"}}>
                	Просроченная сумма долга: <b fontWeight="fontWeightBold">{this.state.supplierClient.debtOverdue}</b>
                </Typography>
                {this.state.supplierClient.debtReserved != null && 
	                <Typography variant="h4" style={{fontSize: "15px", lineHeight: "170%"}}>
	                	Сумма резервов по всем заказам: <b fontWeight="fontWeightBold">{this.state.supplierClient.debtReserved}</b>
	                </Typography>
                }
                {this.state.supplierClient.debtRemain != null && 
	                <Typography variant="h4" style={{fontSize: "15px", lineHeight: "170%"}}>
	                	Остаток лимита: <b fontWeight="fontWeightBold">{this.state.supplierClient.debtRemain}</b>
	                </Typography>
                }
                <br/>
                <span style={{color: "gray"}}>Последнее обновление:&nbsp;
                <Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY HH:mm">{this.state.supplierClient.dateUpd}
                </Moment>
                </span>
                
              </CardBody>
            </Card>
          </GridItem>
          }
        </GridContainer>
        
        <GridContainer id="welcome-block" style={{display: "none"}}>
          <GridItem xs={12} sm={12} md={8}>
          	<Card>
              <CardBody>
              		<Typography id="welcome-text" variant="h4" style={{fontSize: "15px", lineHeight: "170%"}}>
              			
              		</Typography>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        
        
        {(isAdmin || (this.state.news && this.state.news.length > 0)) &&
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
          
			<EditArticleLightbox isAdmin={isAdmin} isManager={isManager} i18n={i18n} innerRef={this.editArticleLBRef} handleProgressBarOpen={this.props.handleProgressBarOpen} 
          							type={this.state.articlesTabActiveId} handleProgressBarClose={this.props.handleProgressBarClose} handleShowSnackbar={this.props.handleShowSnackbar} onClose={() => {this.loadArticles(1); this.loadArticles(2);}} />

			<SendArticleLightbox isAdmin={isAdmin} isManager={isManager} i18n={i18n} innerRef={this.sendArticleLBRef} handleProgressBarOpen={this.props.handleProgressBarOpen} 
          							type={this.state.articlesTabActiveId} handleClose={this.handleSendLBClose} handleProgressBarClose={this.props.handleProgressBarClose} handleShowSnackbar={this.props.handleShowSnackbar} onClose={() => {this.loadArticles(1); this.loadArticles(2);}} />
         	
            <CustomTabs
              headerColor="info"
              isAdmin={isAdmin}
              isManager={isManager}
			  i18n={i18n}
	          getTabActiveId={this.getArticlesTabActiveId} 
			  addClick={() => {if (this.editArticleLBRef && this.editArticleLBRef.current) { this.editArticleLBRef.current.handleOpen();} this.setState({ state: this.state }); }}
			  sendClick={() => {if (this.sendArticleLBRef && this.sendArticleLBRef.current) { this.sendArticleLBRef.current.handleOpen();} this.setState({ state: this.state }); }}
			  handleProgressBarOpen={this.props.handleProgressBarOpen}
			  handleProgressBarClose={this.props.handleProgressBarClose}
			  handleShowSnackbar={this.props.handleShowSnackbar}
			  tabs={[
				  ((this.state.actions.length > 0 || isAdmin) && {
                  tabName: i18n.t('actions'),
                  tabIcon: ViewHeadline,
                  tabId: 2,
                  tabContent: (
                    <Articles
                      articles={this.state.actions}
                      isAdmin={isAdmin}
                      isManager={isManager}
                      i18n={i18n}
                      editClick={this.editArticleLBRef && this.editArticleLBRef.current ? this.editArticleLBRef.current.handleOpen : null}
					    sendClick={this.sendArticleLBRef && this.sendArticleLBRef.current ? this.sendArticleLBRef.current.handleOpen : null}
                      handleProgressBarOpen={this.props.handleProgressBarOpen}
			  		  handleProgressBarClose={this.props.handleProgressBarClose}
			  		  handleShowSnackbar={this.props.handleShowSnackbar}
			  		  onDelete={() => this.loadArticles(2)}
	                  type={2}
                    />
                  )
                }),
                                
                               
                {
                  tabName: i18n.t('News'),
                  tabIcon: ViewHeadline,
                  tabId: 1,
                  tabContent: (
                    <Articles
                      articles={this.state.news}
                      isAdmin={isAdmin}
                      isManager={isManager}
                      i18n={i18n}
                      editClick={this.editArticleLBRef && this.editArticleLBRef.current ? this.editArticleLBRef.current.handleOpen : null}
					                      sendClick={this.sendArticleLBRef && this.sendArticleLBRef.current ? this.sendArticleLBRef.current.handleOpen : null}
			                      handleProgressBarOpen={this.props.handleProgressBarOpen}
			  		  handleProgressBarClose={this.props.handleProgressBarClose}
			  		  handleShowSnackbar={this.props.handleShowSnackbar}
			  		  onDelete={() => this.loadArticles(1)}
	                  type={1}
                    />
                  )
                },
                /*
                {
                  tabName: i18n.t('sales'),
                  tabIcon: Code,
                  tabContent: (
                    <Articles
                      
                      isAdmin={isAdmin}
                      isManager={isManager}
                      i18n={i18n}
                    />
                  )
                },
                
                {
                  tabName: i18n.t('discounts'),
                  tabIcon: Cloud,
                  tabContent: (
                    <Articles
                      articles={this.state.news}
                      isAdmin={isAdmin}
                      isManager={isManager}
                      i18n={i18n}
                    />
                  )
                } */
              ]}
            />
          </GridItem>
          
        </GridContainer>
        }
        
        
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
