import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormControl from '@material-ui/core/FormControl';

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';

import TablePagination from '@material-ui/core/TablePagination';

import Cancel from "@material-ui/icons/Cancel";
import GetApp from '@material-ui/icons/GetApp';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import avatar from "assets/img/faces/marc.jpg";

import Moment from 'react-moment';
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import NativeSelect from '@material-ui/core/NativeSelect';

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FilterSidebar from "components/Sidebar/FilterSidebar.jsx";
import FilterListIcon from '@material-ui/icons/FilterList';

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost, addParameter, removeParameter, getParams, checkboxToggle, getSettingValue, getSettingValueAsBoolean } from "variables/common.js";

const styles = theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  lightbox: {
    position: 'absolute',
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  orderStatusSelector: {
  	'&:before': {
            border: 'none',
        },
    paddingLeft: '3px',
    minWidth: '97px'
  },
  cancelIconWrapper: {
    right: "10px",
    top: "10px",
    position: "absolute",
  },
  cancelIcon: {
    width: "15px",
    height: "15px",
    fontSize: "15px"
  },
  modalTable: {
    marginTop: "40px"
  },
  idButton: {
    backgroundColor: "transparent",
    padding: 0,
    minWidth: 0,
    font: "600 13.3333px Arial",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline"
    }
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    "& > table > tbody": {
      overflow: "auto",
      maxHeight: "60vh"
    },
    "& > table td": {
      paddingLeft: "10px",
      paddingRight: "15px"
    },
    "& > table td:nth-child(1)": { 
      paddingLeft: "24px",	
    },
    "& > table th": {
      paddingLeft: "10px",
      paddingRight: "15px"
    },
    "& > table th:nth-child(1)": { 
      paddingLeft: "24px",	
    },
    "& > table td:last-child": { 
      paddingRight: "15px",
      maxWidth: "115px", 
    },
    maxHeight: "calc(100vh - 60px - 64px - 56px - 74px)",
    overflow: "auto",
  },
  textInputWrapper: {
    margin: 0,
    paddingBottom: 3,
    width: "80%",
    
  },
  textInput: {
  	fontSize: "13px"
  },
  hoverUnderline: {
	"&:hover": {
		textDecoration: "underline"
    }
  },
  removeButton: {
    width: "30px",
    minWidth: "30px",
    height: "30px",
    //margin: "0 20px 0px 30px",
   // verticalAlign: "middle",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
     },
  },
 
  
});

function getModalStyle() {
  const vertical = 5;
  const horizontal = 15;

  return {
    top: `${vertical}%`,
    left: `${horizontal}%`,
//    bottom: `${vertical}%`,
    overflow: "auto",
    maxHeight: "600px",
    minHeight: "inherit"
  };
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, sortDirection, sortBy, numSelected, rowCount, i18n, classes, supplierClientId, isAdmin, isSupervisor } = this.props;
    
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;

    const rows = [
      { id: 'id', numeric: false, disablePadding: false, label: i18n.t('orderId') },
      { id: 'manager', numeric: false, disablePadding: false, label: i18n.t('manager'), sortable: true, hidden: (isAdmin && supplierUser.isManager == 0 ? false : true) },
      { id: 'contractor', numeric: false, disablePadding: false, label: i18n.t('contractor'), sortable: true, hidden: (supplierClientId ? true : false) },
      { id: 'user', numeric: false, disablePadding: false, label: i18n.t('orderedBy'), sortable: true },
      { id: 'comment', numeric: false, disablePadding: false, label: i18n.t('comment') },
      { id: 'supplierComment', numeric: false, disablePadding: false, label: i18n.t('supplierComment') },
      { id: 'warehouse', numeric: false, disablePadding: false, label: i18n.t('warehouse'), hidden: (!getSettingValueAsBoolean('PRICELIST_SHOW_WAREHOUSE', false) ? true : false) },
      { id: 'amount', numeric: true, disablePadding: false, label: i18n.t('amount') },
      { id: 'itemsCount', numeric: true, disablePadding: false, label: i18n.t('itemsCount') },
      { id: 'dateAdd', numeric: true, disablePadding: false, label: i18n.t('dateAdd') },
      { id: 'status', numeric: false, disablePadding: false, label: i18n.t('status'), sortable: true, field: "externalStatusText" },
    ];

    return (
      <TableHead>
        <TableRow >
          {rows.map(
            row => (
              (row.hidden === undefined || !row.hidden ? [
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={sortBy === row.id || (row.field && sortBy === row.field) ? sortDirection : false}
                className={classes.headerCell}
              >
                {row.label !== '' ?
                
                	[
                	row.sortable !== false ? [
	                <Tooltip
	                  title={i18n.t('sort')}
	                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
	                  enterDelay={300}
	                >
                    <TableSortLabel
                      active={sortBy === row.id || (row.field && sortBy === row.field)}
                      direction={sortDirection}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                    </Tooltip>
                    ]
                    : row.label
                    
                    ]
                
               
                : null}
              </TableCell>
              ] : ""
              )
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "table",
    width: "400px",
    textAlign: "right"
  },
});

let EnhancedTableToolbar = props => {
  const { i18n, numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root)}
    >
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {i18n.t('orders')}
        </Typography>
      </div>

          <div className={classes.spacer} />
      	  <div className={classes.actions}>
      	  	<Tooltip title={ i18n.t('download') }>
              <IconButton id="download-orders" style={{display: "inline-block"}} aria-label="Download" href="#">
                <GetApp />
              </IconButton>
            </Tooltip>
            
            
            <Tooltip title={ i18n.t('filters') }>
              <IconButton style={{display: "inline-block"}} aria-label="Filter list" onClick={props.handleFilterClick}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            
      	  </div>
      	  
      	  
      
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      orderItems: [],
      orderOpen: false,
      order: null,
      orderIds: [],
      page: 0,
      rowsPerPage: 50,
      sortDirection: 'desc',
      sortBy: 'dateAdd',
      edit: '',
      statusSelectedFilterItems: [],
      contractorFilterItems: [],
      contractorSelectedFilterItems: [],
      minPriceSelected: null,
      maxPriceSelected: null,
      
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page : page }, () => this.getOrders());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value }, () => this.getOrders());
  };

  handleRequestSort = (event, property) => {
  
    var s = property;
    
    if (s && s == 'status') {
    	s = 'externalStatusText';
    }
  
    const isDesc = this.state.sortBy === s && this.state.sortDirection === 'desc';
    this.setState({ sortBy: s, sortDirection: (isDesc ? 'asc' : 'desc') }, () => this.getOrderIds());
  }

  componentDidMount = () => {
    // this.getOrderIds();
    // this.updateFilterItems();
  }

  getOrderIds = () => {
    const { user, supplierId, isAdmin, isClientAdmin, edit, isSupervisor } = this.props;

    var supplierClient = null;
    if (localStorage.getItem('supplierClient')) {
	    supplierClient = JSON.parse(localStorage.getItem('supplierClient'));
    }

//	var user = JSON.parse(localStorage.getItem('user')).value;
	var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/order-ids?key=' + constants.apiKey + '&upd=' + new Date().getTime() + "&supplierId=" + supplierId
    				+ "&principalId=" + user.id
    				//+ (!isAdmin ? "&userId=" + user.id : "")
    				+ (!isAdmin && supplierClient ? "&supplierClientId=" + supplierClient.id : "")
    				+ (supplierUser.isManager == 1 ? "&supplierManagerUserId=" + supplierUser.id : "")
    				+ "&sortBy=" + this.state.sortBy + "&sort=" + this.state.sortDirection;
    				
    this.state.contractorSelectedFilterItems.map(function(contractorSelected) {
      url += "&contractor=" + encodeURIComponent(contractorSelected);
    });

    this.state.statusSelectedFilterItems.map(function(statusSelected) {
      url += "&status=" + statusSelected;
    });

    if (this.state.minPriceSelected !== null && (this.state.minPriceSelected !== this.state.minPrice || this.state.maxPriceSelected !== this.state.maxPrice)) {
      url += "&minPrice=" + this.state.minPriceSelected;
    }

    if (this.state.maxPriceSelected !== null && (this.state.minPriceSelected !== this.state.minPrice || this.state.maxPriceSelected !== this.state.maxPrice)) {
      url += "&maxPrice=" + this.state.maxPriceSelected;
    }    				

    var that = this;

    var cb = function(data) { 
      that.setState({ orderIds: data.ids }, () => that.getOrders());
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar, this.props.handleShowSnackbar);
    
    if (document.getElementById("download-orders")) {
      	document.getElementById("download-orders").href=(url.replace('/order-ids', '/' + JSON.parse(localStorage.getItem('supplierUser')).value.supplier.name + '/orders/xls'));
      	document.getElementById("download-orders").target = "_blank";
    }
  }
  
  updateFilterItems = () => {
  
    const { user, supplierId, isAdmin, isClientAdmin, edit } = this.props;
  
    var supplierClient = null;
    if (localStorage.getItem('supplierClient')) {
	    supplierClient = JSON.parse(localStorage.getItem('supplierClient'));
    }

//	var user = JSON.parse(localStorage.getItem('user')).value;
	var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;
    
    var url = constants.apiBaseUrl + '/b2b/supplier/order-ids?key=' + constants.apiKey + '&upd=' + new Date().getTime() + "&supplierId=" + supplierId
    				+ "&principalId=" + user.id
    				//+ (!isAdmin ? "&userId=" + user.id : "")
    				+ (!isAdmin && supplierClient ? "&supplierClientId=" + supplierClient.id : "")
    				+ (supplierUser.isManager == 1 ? "&supplierManagerUserId=" + supplierUser.id : "")
    				+ "&sortBy=" + this.state.sortBy + "&sort=" + this.state.sortDirection;

	if (this.state.statusSelectedFilterItems) {
		this.state.statusSelectedFilterItems.map(function(statusSelected) {
  			url += "&status=" + statusSelected;
		});
	}

    var that = this;
    
    var cb = function(data) { 
      that.makeFilterItems(that, data.contractorFilterItems, data.minPrice, data.maxPrice);
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }
  
  makeFilterItems = (that, contractors, minPrice, maxPrice) => {

    that.setState({ minPrice: minPrice <= 0 ? 0 : Math.floor(minPrice)});
    that.setState({ maxPrice: Math.ceil(maxPrice)});

    if(maxPrice !== 0) {

      if (that.state.minPriceSelected !== null && that.state.minPriceSelected < minPrice) { 
        removeParameter("minPrice", that.state.minPriceSelected);
        that.setState({ minPriceSelected: minPrice });
        addParameter("minPrice", minPrice);
      } else if (that.state.minPriceSelected === null) {
        that.setState({ minPriceSelected: that.state.minPrice });
      }

      if (that.state.maxPriceSelected !== null && that.state.maxPriceSelected > maxPrice) { 
        removeParameter("maxPrice", that.state.maxPriceSelected);
        that.setState({ maxPriceSelected: maxPrice });
        addParameter("maxPrice", maxPrice);
      } else if (that.state.maxPriceSelected === null) {
        that.setState({ maxPriceSelected: that.state.maxPrice });
      }
    }

    if(contractors.length === 0) { // || contractors.length > 100
      that.setState({ contractorFilterItems: [] });
    } else {
      that.setState({ contractorFilterItems: contractors }, () => {
        var newContractorSelectedFilterItems = [];
        var oldContractorSelectedFilterItems = that.state.contractorSelectedFilterItems;

        that.state.contractorSelectedFilterItems.map(function(contractor) {
          if (that.state.contractorFilterItems.indexOf(contractor) === -1) {
            removeParameter("contractor", contractor);
            newContractorSelectedFilterItems = checkboxToggle(contractor, oldContractorSelectedFilterItems);
            oldContractorSelectedFilterItems = newContractorSelectedFilterItems;
          } else {
            if(newContractorSelectedFilterItems.indexOf(contractor) === -1) {
              newContractorSelectedFilterItems.push(contractor);
            }
          }
        });

        that.setState({ contractorSelectedFilterItems: newContractorSelectedFilterItems });
      });
    }

  }

  init = () => {
    var params = getParams(window.location.href);

    var orderBy = params.orderBy === undefined ? 'category' : params.orderBy;
    var order = params.order === undefined ? 'asc' : params.order;
    var contractorSelectedFilterItems = params.contractor === undefined ? [] : params.contractor;
    var minPriceSelected = params.minPrice === undefined ? null : params.minPrice;
    var maxPriceSelected = params.maxPrice === undefined ? null : params.maxPrice;

    var statusSelectedFilterItems = params.status;

    if (params.status === undefined) {
   	  statusSelectedFilterItems = [];
    } 

    this.setState({ 
      orderBy: orderBy,
      order: order,
      contractorSelectedFilterItems: contractorSelectedFilterItems,
      statusSelectedFilterItems: statusSelectedFilterItems,
      minPriceSelected: minPriceSelected,
      maxPriceSelected: maxPriceSelected,
    }, () => { this.updateFilterItems(); this.getOrderIds(); });

    // this.getTotalPricelistSize();
  }

	componentWillMount() {
      this.init();
	}
	
  getOrders = () => {
    const { orderIds, page, rowsPerPage } = this.state;

	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/orders?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();

    
    orderIds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(id => {
      url += "&ids=" + id;
    })
    
    if (url.indexOf('&ids=') == -1) {
    	url += '&ids=-1';
    }

    var that = this;

    var cb = function(data) { 
      that.setState({ orders: data });
      that.setState({edit: ""});
    };

    this.props.handleProgressBarOpen();
    if (orderIds.length > 0) {
    	doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar, this.props.handleShowSnackbar);
    } else {
    	that.setState({ orders: [] });
    }
    
  }

  handleOrderClick = (order) => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/order?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierOrderId=" + order.id;

    var that = this;

    var cb = function(data) { 
      that.setState({ 
        orderItems: data.supplierOrderItems,
        order: data,
        orderOpen: true,
        orderConfirmedAmount: data.supplierOrderItems.reduce((totalQuantity, orderItem) => totalQuantity + (orderItem.quantityConfirmed ? orderItem.quantityConfirmed : 0) * orderItem.price, 0),
        orderConfirmedQuantity: data.supplierOrderItems.reduce((totalQuantity, orderItem) => totalQuantity + (orderItem.quantityConfirmed ? orderItem.quantityConfirmed : 0) , 0),
      });
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  handleOrderClose = () => {
    this.setState({ orderOpen: false });
  }
  
  handleEditOpen = (event, id, fieldName, fieldValue) => {
	this.setState({ edit: fieldName + '-' + id }, () => {if (document.getElementById(fieldName + '-' + id)) {document.getElementById(fieldName + '-' + id).value = (fieldValue ? fieldValue : '');}}); 
  }

  handleEditClose = event => {

    if (event.key === "Enter" || event.type === "blur" || event.type === "click") {

      var supplierOrderId = this.state.edit.split('-')[1];
      var fieldName = this.state.edit.split('-')[0];
      var fieldValue = event.target.value;
      
      var user = JSON.parse(localStorage.getItem('user')).value;

  	  var url = constants.apiBaseUrl + '/b2b/supplier/order/update?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();

  	  var body = "supplierOrderId=" + supplierOrderId + "&" + fieldName + "=" + encodeURIComponent(fieldValue);

      this.props.handleProgressBarOpen();
      doAjaxPost(url, body, null, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
      
      for (var i = 0; i < this.state.orders.length; i++) {
        var order = this.state.orders[i];
      	if (order.id == supplierOrderId) {
      		if (fieldName == 'comment') {
	      		order.comment = fieldValue;
      		} else if( fieldName == 'supplierComment'){
	            order.supplierComment = fieldValue;
            }
      		break;
      	}
      }
      
    }
  };
  
  handleOrderStatusSelected = (event, orderId) => {

	  var user = JSON.parse(localStorage.getItem('user')).value;

  	  var url = constants.apiBaseUrl + '/b2b/supplier/order/update?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();

  	  var body = "supplierOrderId=" + orderId + "&orderStatusId=" + event.target.value;

      this.props.handleProgressBarOpen();
      doAjaxPost(url, body, () => {this.getOrders();  }, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  };
  
  handleOrderItemStatusSelected = (orderStatusId, orderItemId, quantityConfirmed) => {
	    var user = JSON.parse(localStorage.getItem('user')).value;

  	  var url = constants.apiBaseUrl + '/b2b/supplier/order/item/update?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();

  	  var body = "supplierOrderItemId=" + orderItemId + "&orderStatusId=" + orderStatusId + (quantityConfirmed ? "&quantityConfirmed=" + quantityConfirmed : '') ;
      this.props.handleProgressBarOpen();
      doAjaxPost(url, body, () => {this.getOrders(); this.handleOrderClick(this.state.order)}, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  };
  
  handleFilterClick = () => {
    this.setState({ showFilterSidebar: true });
  }

  handleFiltersClose = () => {
    this.setState({ showFilterSidebar: false });
  }

  handleFilterToggle = () => {
    this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
  };  
  
  handlePriceFilterChangeComplete = (minPrice, maxPrice) => {

    removeParameter("minPrice", this.state.minPriceSelected);
    addParameter("minPrice", minPrice);
    removeParameter("maxPrice", this.state.maxPriceSelected);
    addParameter("maxPrice", maxPrice);

    this.setState({ minPriceSelected: minPrice, maxPriceSelected: maxPrice },  () => this.getOrderIds());
  };
  
  handleSelectFilterContractor = (event, contractor) => {

    if (this.state.contractorSelectedFilterItems.indexOf(contractor) === -1) {
      addParameter("contractor", contractor);
    } else {
      removeParameter("contractor", contractor);
    }

    var newContractorSelectedFilterItems = checkboxToggle(contractor, this.state.contractorSelectedFilterItems);

    this.setState({ 
      contractorSelectedFilterItems: newContractorSelectedFilterItems,
      selectedFeatureFilters: {}
    }, () => this.getOrderIds());
  }
 
  handleSelectFilterStatus = (event, status) => {

    if (this.state.statusSelectedFilterItems.indexOf(status) === -1) {
      addParameter("status", status);
    } else {
      removeParameter("status", status);
    }
    
    var newStatusSelectedFilterItems = checkboxToggle(status, this.state.statusSelectedFilterItems);

    this.setState({ statusSelectedFilterItems: newStatusSelectedFilterItems },  () => this.getOrderIds());

  }

 removeOrderItem = (event) =>{
    const id = event.currentTarget.dataset.id;
	    if (!window.confirm("Удалить товар?")) {
    	    return;
        }else{
	           var newOrderItems = this.state.orderItems.filter(n => n.externalId !== id);
	           var deleteItem =this.state.orderItems.filter(n => n.externalId == id);
	           var newOrderAmount = this.state.order.amount-deleteItem[0].price*deleteItem[0].quantity;
               this.setState({order:{...this.state.order, amount: newOrderAmount },orderItems:newOrderItems})
        }
	}

  selectAllFilterItems = (filterName, items) => {

    var selectedListName = filterName + 'SelectedFilterItems';

    removeParameter(filterName, "[^&]*");

    var that = this;

    if (items.length > 0) {
      items.map(function(item) {
        addParameter(filterName, item);
      });
    }
    
    this.setState({ 
      [selectedListName]: items
    }, () => this.getOrderIds());
  }

  render() {
    const { classes, i18n, isAdmin, isManager, supplierClientId, isClientAdmin, isSupervisor} = this.props;
    const { rowsPerPage, page, orders, sortDirection, sortBy, orderIds } = this.state;
    const modalStyle = getModalStyle();
    
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;

    return (
      <div>
        <Modal
          open={this.state.orderOpen}
          onClose={this.handleOrderClose}
        >
          <div>
            <div style={modalStyle} className={ classNames(classes.lightbox, classes.imageLightbox) }>
            <Typography variant="h6">
               {i18n.t('order')} #{this.state.order !== null && (this.state.order.externalId ? this.state.order.externalId : this.state.order.id)}
            </Typography>
            <IconButton 
              onClick={this.handleOrderClose} 
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
              <div style={{margin: "auto"}}>
                <Table className={classes.modalTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{paddingRight:"5px"}}>
                       {i18n.t('article')}
                      </TableCell>
                      <TableCell style={{whiteSpace: "nowrap", paddingLeft:"5px"}}>
                        {i18n.t('model')}
                      </TableCell>
                      {getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) && <TableCell align="right" style={{paddingRight:"7px"}}>
                          {i18n.t('date')}
                      </TableCell>}
                      <TableCell align="right" style={{paddingRight:"5px"}}>
                        {i18n.t('priceWithVAT')}
                      </TableCell>
                       <TableCell align="left" style={{paddingRight:"5px"}}>
                        {i18n.t('warehouse')}
                      </TableCell>
                      <TableCell align="right" style={{paddingRight:"5px"}}>
                        {i18n.t('quantity') + ' / ' + i18n.t('confirmed')}
                      </TableCell>
                      <TableCell align="right" style={{paddingRight:"1px"}}>
                        {i18n.t('amount') + ' / ' + i18n.t('confirmed')}
                      </TableCell>
                      <TableCell style={{whiteSpace: "nowrap"}}>
                        {i18n.t('status')}
                      </TableCell>
                      <TableCell align="right">
                          
                        </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.orderItems
                      .map(orderItem => {
                        return (
                          <TableRow hover>
                          	<TableCell style={{paddingRight:"5px"}}>
                          {orderItem.externalId ? orderItem.externalId : orderItem.supplierPriceRowAssociation.externalId}
                          	</TableCell>
                            <TableCell style={{whiteSpace: "nowrap", paddingRight:"5px", paddingLeft:"5px"}}>
                              {orderItem.producer ? orderItem.producer : orderItem.supplierPriceRowAssociation.producer}
                              &nbsp;
                              {orderItem.model ? orderItem.model : orderItem.supplierPriceRowAssociation.model}
                            </TableCell>
                              {getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) && <TableCell align="right" style={{whiteSpace: "nowrap", paddingRight:"5px"}}>
                               {orderItem.availabilityDate &&<Moment parse="DD.MM.YYYY HH:mm:ss" format={getSettingValue("PRICELIST_DATE_FORMAT", "DD.MM.YY")}>
                               	{orderItem.availabilityDate}
	                              </Moment>}
                            </TableCell>}
                            <TableCell align="right" style={{paddingRight:"5px"}}>
                              {orderItem.price}
                            </TableCell>
                            <TableCell align="left" style={{paddingRight:"5px"}}>
                              {orderItem.warehousesText}
                            </TableCell>
                            <TableCell align="right" style={{paddingRight:"5px"}}>
                              {orderItem.quantity +' / '}
						                              {(isAdmin || isManager) ? <CustomInput
                                formControlProps={{
                                  className: classes.textInputWrapper,
                                  style:{ width: "25%"}
                                }}
                                inputProps={{
	                                  defaultValue:orderItem.quantityConfirmed !== undefined ? orderItem.quantityConfirmed : null,
                                  placeholder: orderItem.quantityConfirmed !== undefined ? orderItem.quantityConfirmed : orderItem.quantity,
                                  inputProps: {
                                    className: classes.textInput,
                                    style:{ textAlign: "right"}
                                  },
                                  onBlur: event => this.handleOrderItemStatusSelected(orderItem.orderStatusId ? orderItem.orderStatusId : this.state.order.orderStatusId, orderItem.id, event.target.value),
                                }}
                              /> : (orderItem.quantityConfirmed !== undefined ? orderItem.quantityConfirmed : ' - ')}
                            </TableCell>
                            <TableCell align="right" style={{paddingRight:"5px"}}>
                              {Math.round(orderItem.quantity * orderItem.price * 100) / 100 + ' / ' + (orderItem.quantityConfirmed !== undefined ? Math.round(orderItem.quantityConfirmed * orderItem.price * 100) / 100 : ((isAdmin || isManager) ? 0 : ' - '))}
                            </TableCell>

                            <TableCell align="left" style={{paddingRight:"0px"}} >
                            {(isAdmin || isManager) && orderItem.externalStatusText == null ? 
                              		<NativeSelect
	                                id={"order-status-" + orderItem.id}
	                                name="orderStatus"
	                                className={ classNames(classes.textInput, classes.orderStatusSelector) }
	                                onChange={event => this.handleOrderItemStatusSelected(event.target.value, orderItem.id, orderItem.quantityConfirmed)}
	                                style={{backgroundColor: (orderItem.orderStatusId ? 
                                  (orderItem.orderStatusId == 1 ? 'lightgreen' : (orderItem.orderStatusId == 7 ? 'yellow' : (orderItem.orderStatusId == 5 ? 'lightskyblue' : (orderItem.orderStatusId == 6 ? 'tomato' : 'inherit'))))
                                  : 
                                  (this.state.order.orderStatusId == 1 ? 'lightgreen' : (this.state.order.orderStatusId == 7 ? 'yellow' : (this.state.order.orderStatusId == 5 ? 'lightskyblue' : (this.state.order.orderStatusId == 6 ? 'tomato' : 'inherit'))))
                                  )
                            }}
	                            >
	                              <option className={classes.menuItem} value="1" selected={orderItem.orderStatusId ? orderItem.orderStatusId == 1 && true : this.state.order.orderStatusId == 1 && true}>{i18n.t('orderStatus-1')}</option>
	                              <option className={classes.menuItem} value="8" selected={orderItem.orderStatusId ? orderItem.orderStatusId == 8 && true : this.state.order.orderStatusId == 8 && true}>{i18n.t('orderStatus-8')}</option>
	                              <option className={classes.menuItem} value="9" selected={orderItem.orderStatusId ? orderItem.orderStatusId == 9 && true : this.state.order.orderStatusId == 9 && true}>{i18n.t('orderStatus-9')}</option>
	                              <option className={classes.menuItem} value="7" selected={orderItem.orderStatusId ? orderItem.orderStatusId == 7 && true : this.state.order.orderStatusId == 7 && true}>{i18n.t('orderStatus-7')}</option>
	                              <option className={classes.menuItem} value="4" selected={orderItem.orderStatusId ? orderItem.orderStatusId == 4 && true : this.state.order.orderStatusId == 4 && true}>{i18n.t('orderStatus-4')}</option>
	                              <option className={classes.menuItem} value="5" selected={orderItem.orderStatusId ? orderItem.orderStatusId == 5 && true : this.state.order.orderStatusId == 5 && true}>{i18n.t('orderStatus-5')}</option>
	                              <option className={classes.menuItem} value="6" selected={orderItem.orderStatusId ? orderItem.orderStatusId == 6 && true : this.state.order.orderStatusId == 6 && true}>{i18n.t('orderStatus-6')}</option>
	                              
	                              </NativeSelect>
	                          	  : 
                      	        orderItem.externalStatusText ? orderItem.externalStatusText : i18n.t('orderStatus-' + (orderItem.orderStatusId ? orderItem.orderStatusId : this.state.order.orderStatusId))
                               }
                             </TableCell>
                             <TableCell 
                              component="td" 
                              scope="row"
                              padding="none"
                              align="left"
                              style={{paddingLeft:0, width: "1px"}}
                              >
                              {isSupervisor && <Button className={classes.removeButton} disableRipple="true" data-id={orderItem.externalId}  onClick={ event => this.removeOrderItem(event) }>×</Button>}
                              </TableCell>
                          </TableRow>
                      )})}
                      <TableRow>
                        <TableCell>
                          
                        </TableCell>
                        <TableCell align="right">
                          
                        </TableCell>
                        
                        {getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) && <TableCell align="right">
                        </TableCell>}
                        
                        <TableCell align="right" style={{color: '#aaa', paddingRight:"5px"}}>
                          {Math.round(this.state.orderItems.reduce((totalPrice, orderItem) => totalPrice + orderItem.price, 0) * 100) / 100}
                        </TableCell>
                        <TableCell align="right" style={{color: '#aaa', paddingRight:"5px"}}>
                          {this.state.orderItems.reduce((totalQuantity, orderItem) => totalQuantity + orderItem.quantity, 0) + ' / ' + (isAdmin || isManager ? this.state.orderConfirmedQuantity : (this.state.orderConfirmedQuantity == 0 ? ' - ' : this.state.orderConfirmedQuantity ))}
                        </TableCell>
                        <TableCell align="right" style={{fontWeight: 'bold', paddingRight:"5px"}}>
                          {this.state.order !== null && this.state.order.amount + ' / ' + ((isAdmin || isManager) ? Math.round(this.state.orderConfirmedAmount * 100) / 100 : (this.state.orderConfirmedAmount == 0 ? ' - ' : Math.round(this.state.orderConfirmedAmount * 100) / 100 ))}
                        </TableCell>
                         <TableCell align="right">
                          
                        </TableCell>
                        <TableCell>
                          
                        </TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </div>
              
              {this.state.order && this.state.order.comment && 
              	<div style={{marginTop: "30px", fontSize: "15px"}}>
              		{i18n.t('comment')}: {this.state.order.comment}<br/>
              	</div>
              }
              
              {this.state.order && this.state.order.shippingDate &&
              		<>
              		{i18n.t('shippingDate')}: <Moment parse="DD.MM.YYYY" format="DD.MM.YYYY">{this.state.order.shippingDate}</Moment><br/>
              		</>
              }
              
              {this.state.order && this.state.order.supplierClientAddress &&
              	<>
              		{i18n.t('address')}: {" "}
              					{this.state.order.supplierClientAddress.type == 1 ? "ПВЗ" : "доставка"}{" - "} 
                                {this.state.order.supplierClientAddress.city ? "г. " + this.state.order.supplierClientAddress.city.name + ", " : ""}
                                {this.state.order.supplierClientAddress.address}
                    <br/>
                </>
              }
              
              <div style={{marginTop: "30px", fontSize: "15px"}}>
              	
              	{i18n.t('date')}: <Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY HH:mm">{this.state.order.dateAdd}</Moment><br/>
              	
              	
                {i18n.t('status')}: 
                {(isAdmin || isManager) && this.state.order.externalStatusText == null ?
                          		<NativeSelect
	                              id={"order-status-" + this.state.order.id}
	                              name="orderStatus"
	                              className={ classNames(classes.textInput, classes.orderStatusSelector) }
	                              onChange={event => this.handleOrderStatusSelected(event, this.state.order.id)}
	                              style={{backgroundColor: (this.state.order.orderStatusId == 1 ? 'lightgreen' : (this.state.order.orderStatusId == 7 ? 'yellow' : (this.state.order.orderStatusId == 5 ? 'lightskyblue' : (this.state.order.orderStatusId == 6 ? 'tomato' : 'inherit')))) }}
	                            >
	                              <option className={classes.menuItem} value="1" selected={this.state.order.orderStatusId == 1 && true}>{i18n.t('orderStatus-1')}</option>
	                              <option className={classes.menuItem} value="8" selected={this.state.order.orderStatusId == 8 && true}>{i18n.t('orderStatus-8')}</option>
	                              <option className={classes.menuItem} value="9" selected={this.state.order.orderStatusId == 9 && true}>{i18n.t('orderStatus-9')}</option>
	                              <option className={classes.menuItem} value="7" selected={this.state.order.orderStatusId == 7 && true}>{i18n.t('orderStatus-7')}</option>
	                              <option className={classes.menuItem} value="4" selected={this.state.order.orderStatusId == 4 && true}>{i18n.t('orderStatus-4')}</option>
	                              <option className={classes.menuItem} value="5" selected={this.state.order.orderStatusId == 5 && true}>{i18n.t('orderStatus-5')}</option>
	                              <option className={classes.menuItem} value="6" selected={this.state.order.orderStatusId == 6 && true}>{i18n.t('orderStatus-6')}</option>
	                              
	                            </NativeSelect>
	                          	: 
                          		this.state.order.externalStatusText ? this.state.order.externalStatusText : i18n.t('orderStatus-' + this.state.order.orderStatusId)
                          	}
               <br/>
                
                {isAdmin && this.state.order && this.state.order.user &&
                <>
              	{i18n.t('orderedBy')}: {this.state.order.user.firstName} {this.state.order.user.lastName}<br/>
              	
              	{this.state.order.supplierClient && <span>
              	{i18n.t('contractor')}: {this.state.order.supplierClient.name}<br/>
              	</span>
              	}
              	
              	{this.state.order.supplierClient && this.state.order.supplierClient.unp && <span>
              	{i18n.t('unp')}: {this.state.order.supplierClient.unp}<br/>
              	</span>
              	}
              	
              	{this.state.order.supplierClient && this.state.order.supplierClient.requisites && <span>
              	{i18n.t('requisites')}: {this.state.order.supplierClient.requisites}<br/>
              	</span>
              	}
              	</>
              	}
              </div>
              
            </div>
          </div>
        </Modal>
        
        <ClickAwayListener onClickAway={this.handleFiltersClose}>
          <FilterSidebar
            handleFilterToggle={this.handleFilterToggle}
            open={this.state.showFilterSidebar}
            i18n={i18n}
            contractorFilterItems={this.state.contractorFilterItems}
            contractorSelectedFilterItems={this.state.contractorSelectedFilterItems}
            handleSelectFilterContractor={this.handleSelectFilterContractor}
            handlePriceFilterChangeComplete={this.handlePriceFilterChangeComplete}
            handleSelectFilterStatus={this.handleSelectFilterStatus}
            statusSelectedFilterItems={this.state.statusSelectedFilterItems}
            allStatuses={[1, 7, 5, 6]}
            minPriceSelected={this.state.minPriceSelected}
            maxPriceSelected={this.state.maxPriceSelected}
            minPrice={this.state.minPrice}
            maxPrice={this.state.maxPrice}
            selectAllFilterItems={this.selectAllFilterItems}
            isAdmin={isAdmin}
            isClientAdmin={isClientAdmin}
            isManager={isManager}
           />
        </ClickAwayListener>  
            
        
        <Paper className={classes.root}>
            <EnhancedTableToolbar i18n={i18n} handleFilterClick={this.handleFilterClick} />
            <div className={classes.tableWrapper}>
              <Table className={classNames(classes.table, "fixed-header-table")} size={'small'}>
                <EnhancedTableHead
                  sortDirection={sortDirection}
                  sortBy={sortBy}
                  onRequestSort={this.handleRequestSort}
                  i18n={i18n}
                  classes={classes}
                  isAdmin={isAdmin}
                  supplierClientId={supplierClientId}
                />
                <TableBody>
                  {orders
                    .map(order => {
                      return (
                        <TableRow hover>
                          <TableCell>
                          	<span onClick={event => this.handleOrderClick(order)} className={classNames(classes.hoverUnderline)} style={{cursor: "pointer", "&:hover": {textDecoration: "underline"}}}>
                            	{order.externalId ? order.externalId : order.id}
                            </span>
                          </TableCell>
                          
                          {(isAdmin && supplierUser.isManager == 0) &&
                          	<TableCell >
                          	  {order.supplierClient && order.supplierClient.supplierManagerUser ? order.supplierClient.supplierManagerUser.user.firstName : ""} {order.supplierClient && order.supplierClient.supplierManagerUser ? order.supplierClient.supplierManagerUser.user.lastName : ""}
                            </TableCell>
                          }
                          
                          
                          {!supplierClientId &&
                            <TableCell >
                              {order.supplierClient ? order.supplierClient.name : ""}
                            </TableCell>
                            }
                          <TableCell >
                            {order.user.firstName ? order.user.firstName : ''} {order.user.lastName ? order.user.lastName : ''}
                          </TableCell>
                          <TableCell onClick={event => { supplierClientId && this.handleEditOpen(event, order.id, "comment", order.comment) }}>
                          	{ (this.state.edit == "comment-" + order.id) ?
                            <CustomInput
                                id={"comment-" + order.id}
                                formControlProps={{
                                  className: classes.textInputWrapper
                                }}
                                inputProps={{
                                  placeholder: "",
                                  inputProps: {
                                    className: classes.textInput,
                                  },
                                  onKeyPress: event => this.handleEditClose(event),
                                  onBlur: event => this.handleEditClose(event),
                                }}
                              />
                              : order.comment }
                          </TableCell>
                         
                          <TableCell onClick={event => { !supplierClientId && (isAdmin || isManager) && this.handleEditOpen(event, order.id, "supplierComment", order.supplierComment) }}>
                          	{ (this.state.edit == "supplierComment-" + order.id) ?
                            <CustomInput
                                id={"supplierComment-" + order.id}
                                formControlProps={{
                                  className: classes.textInputWrapper
                                }}
                                inputProps={{
                                  placeholder: "",
                                  inputProps: {
                                    className: classes.textInput,
                                  },
                                  onKeyPress: event => this.handleEditClose(event),
                                  onBlur: event => this.handleEditClose(event),
                                }}
                              />
                              : order.supplierComment }
                          </TableCell>
                          
                          {getSettingValueAsBoolean('PRICELIST_SHOW_WAREHOUSE', false) && 
                          <TableCell align="left">
                           {order.warehousesText}
                          </TableCell>
                          }
                          
                          <TableCell align="right">
                           {order.amount}
                          </TableCell>
                          <TableCell align="right">
                            {order.itemsCount}
                          </TableCell>
                          <TableCell align="right">
                            <Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY HH:mm">
                              {order.dateAdd ? order.dateAdd : order.dateAddMillis}
                            </Moment>
                          </TableCell>
                          <TableCell align="left">
                          	{(isAdmin || isManager) && order.externalStatusText == null ?
                          		<NativeSelect
	                              id={"order-status-" + order.id}
	                              name="orderStatus"
	                              className={ classNames(classes.textInput, classes.orderStatusSelector) }
	                              onChange={event => this.handleOrderStatusSelected(event, order.id)}
	                              style={{backgroundColor: (order.orderStatusId == 1 ? 'lightgreen' : (order.orderStatusId == 7 ? 'yellow' : (order.orderStatusId == 5 ? 'lightskyblue' : (order.orderStatusId == 6 ? 'tomato' : 'inherit')))) }}
	                            >
	                              <option className={classes.menuItem} value="1" selected={order.orderStatusId == 1 && true}>{i18n.t('orderStatus-1')}</option>
	                              <option className={classes.menuItem} value="8" selected={order.orderStatusId == 8 && true}>{i18n.t('orderStatus-8')}</option>
	                              <option className={classes.menuItem} value="9" selected={order.orderStatusId == 9 && true}>{i18n.t('orderStatus-9')}</option>
	                              <option className={classes.menuItem} value="7" selected={order.orderStatusId == 7 && true}>{i18n.t('orderStatus-7')}</option>
	                              <option className={classes.menuItem} value="4" selected={order.orderStatusId == 4 && true}>{i18n.t('orderStatus-4')}</option>
	                              <option className={classes.menuItem} value="5" selected={order.orderStatusId == 5 && true}>{i18n.t('orderStatus-5')}</option>
	                              <option className={classes.menuItem} value="6" selected={order.orderStatusId == 6 && true}>{i18n.t('orderStatus-6')}</option>
	                              
	                            </NativeSelect>
	                          	: 
                          		order.externalStatusText ? order.externalStatusText : i18n.t('orderStatus-' + order.orderStatusId)
                          	}
                          </TableCell>
                        </TableRow>
                    )})}
                </TableBody>
              </Table>
            </div>
            <div className={classes.tableFooter}>
              <div className={classes.paginationWrapper}>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100, 250, 500]}
                  component="div"
                  count={orderIds.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  labelRowsPerPage={i18n.t('rows_per_page')}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
                />
              </div>
            </div>
        </Paper>
      </div>
    );
  }
}

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
  edit: PropTypes.string.isRequired,
  handleEditOpen: PropTypes.func.isRequired,
  handleEditClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(Orders);
