import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import RTLNavbarLinks from "./RTLNavbarLinks.jsx";
import Button from "components/CustomButtons/Button.jsx";
import WarehouseSelect from "components/WarehouseSelector/WarehouseSelect.jsx"
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import InputLabel from "@material-ui/core/InputLabel";


import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";

import { getSettingValue, getSettingValueAsBoolean } from "variables/common.js";

function Header({ ...props }) {

  const { classes, color, i18n } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });

  function makeBrand() {
    var name;
    props.routes.map((prop, key) => {
      if (prop.layout + prop.path === props.location.pathname) {
        name = i18n.t(prop.name);
      }
      return null;
    });
    return name;
  };

  var brand = (
    <div className={classes.logo}>
      <a
        className={classNames(classes.logoLink)}
      >
        <div className={classes.logoImage}>
          <img src="https://export208.zoomos.by/tmpl/v2/img/b2b/b2b-logo-ico2.ico" alt="logo" className={classes.img} />
        </div>
        ZOOMOS B2B - {props.supplier === null ? null : props.supplier.name}
      </a>
    </div>
  );

  return (
    <AppBar className={classNames(classes.appBar + appBarClasses, props.openSide && classes.appBarShift )}>
      <Toolbar className={classes.container}>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
            className={classNames(props.openSide && classes.menuButtonHidden)}
          >
          <Menu />
        </IconButton>
        <div className={classes.flex} style={{display: 'flex'}}>
          <div style={{display:'inline-block', verticalAlign: 'top', width: '200px'}}>
          <Button color="transparent" href="/pricelist" className={classes.title}>
            { makeBrand() }
          </Button>
          </div>
          
          { getSettingValue('LOGO_HEADER') &&
          	<div style={{display:'inline', verticalAlign: 'top'}}>
          		<img src={getSettingValue('LOGO_HEADER')} alt="" style={{maxHeight: '45px'}} />
        	</div>
          }
          
          {getSettingValueAsBoolean('WAREHOUSE_IS_MANDATORY', false) &&
			  <div style={{ margin:'auto 0'}}>
	          	<WarehouseSelect 
		        	classes={classes} 
		        />
		      </div>
	      }
		  
        </div>
        
        <Hidden smDown implementation="css">
          <AdminNavbarLinks 
            cart={props.cart}
            removeProduct={props.removeProduct}
            handleCart={props.handleCart}
            totalItems={props.totalItems}
            addToCart={props.addToCart}
            totalAmount={props.totalAmount}
            i18n={i18n}
            setLanguage={props.setLanguage}
            handleCheckoutOpen={props.handleCheckoutOpen}
            handleComparatorOpen={props.handleComparatorOpen}
            handleFavoritesOpen={props.handleFavoritesOpen}
            totalItemsToCompare={props.totalItemsToCompare}
            totalFavorites={props.totalFavorites}
            setModelFromNavbar={props.setModelFromNavbar}
          />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(Header);
