import { hexToRgb, whiteColor } from "assets/jss/material-dashboard-react.jsx";

const customTabsStyle = {
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px"
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important"
  },
  displayNone: {
    display: "none !important"
  },
  tabsRoot: {
    minHeight: "unset !important",
    overflowX: "hidden",
    "& $tabRootButton": {
      fontSize: "0.875rem"
    }
  },
  tabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "10px 15px",
    borderRadius: "3px",
    lineHeight: "24px",
    border: "0 !important",
    color: whiteColor + " !important",
    marginLeft: "4px",
    "&:last-child": {
      marginLeft: "0px"
    }
  },
  tabLabelContainer: {
    padding: "0px"
  },
  tabLabel: {
    fontWeight: "500",
    fontSize: "12px"
  },
  tabSelected: {
    backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.2)",
    transition: "0.2s background-color 0.1s"
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    "& > svg,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0"
    }
  },
  scrollButtonGradient: {
	width: "37px",
	height: "50px",
	zIndex: "10",
	position: "absolute",
	//transform: "translate(50%)",
	top: "13px",
	background: "linear-gradient(to left, #fff 0, #fff 70%, rgba(255, 255, 255, 0) 100%)",
	"&:first-child": {
	  backgroundImage: "linear-gradient(to right, rgba(38, 198, 218, 1), rgba(38, 198, 218, 1), rgba(38, 198, 218, 1), rgba(0, 0, 0, 0))",
      left: "0px",
    },
	 "&:last-child": {
	  backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 172, 193, 1), rgba(0, 172, 193, 1), rgba(0, 172, 193, 1))",
      right: "0px",
    },
 } 	
};

export default customTabsStyle;
