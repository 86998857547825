import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Switch, Route, Redirect, withRouter, Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import CategoryNavigation from "components/CategoryNavigation/CategoryNavigation.jsx";

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Check from "@material-ui/icons/Check";

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';

import avatar from "assets/img/faces/marc.jpg";

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost } from "variables/common.js";

const styles = {
  categoriesTree: {
  	width: "100%",
  	columns: "4",
  	'& ul': {
  		paddingLeft: "15px"
  	},
  	'& p': {
  		breakInside: "avoid-column"
  	}
  }
};

class Catalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriesTree: []
    }
  }

  componentDidMount = () => {

    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/categories?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierId=" + this.props.supplierId;

    var that = this;

    var cb = function(data) {
	  //console.log("categoriesTree: ", data)

      that.setState({ categoriesTree: data });
      
    };
    
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  render() {
    const { classes, i18n, isAdmin, isManager, isClientAdmin, isSupervisor, isWidget } = this.props;
    
    return (
      <div>
      	{isWidget ? (				
			<CustomTabs
				noSelected={true}
				headerColor="info"
				i18n={i18n}
				handleProgressBarOpen={this.props.handleProgressBarOpen}
				handleProgressBarClose={this.props.handleProgressBarClose}
				handleShowSnackbar={this.props.handleShowSnackbar}
				tabs={this.state.categoriesTree && this.state.categoriesTree.children
            		? this.state.categoriesTree.children.map((masterCategory, index) => ({
                		tabName: masterCategory.data.name,
                		tabId: index + 1,
                		tabContent: <CategoryNavigation categories={masterCategory.children} classes={classes} />
           			})) : []
				}
 			/>			
		  ) : (
			<Card>
          	  <CardBody style={{overflow: "auto", height: "80vh"}}>
          		<div className={ classNames(classes.categoriesTree) }>
          		{this.state.categoriesTree.children && this.state.categoriesTree.children.map(category => {
          			return (
          			<p>
          			{category.children && category.children.length > 0 ?
		          			<b style={{fontSize: "15px"}}>{category.data.name}</b>
		          			: <Link to={'/pricelist?category=' + category.data.name}>
		          			{category.data.name}
		          			</Link>
	          			}
          			
          			
          			<ul>
          			{category.children && category.children.map(category => {
          				return (
	          			<li>
	          			{category.children && category.children.length > 0 ?
		          			category.data.name
		          			: <Link to={'/pricelist?category=' + category.data.name}>
		          			{category.data.name}
		          			</Link>
	          			}
	          			
	          			<ul>
	          			{category.children && category.children.map(category => {
	          				return (
		          			<li>
		          			{category.children && category.children.length > 0 ?
			          			category.data.name
			          			: <Link to={'/pricelist?category=' + category.data.name}>
			          			{category.data.name}
			          			</Link>
		          			}
		          			</li>
		          			)
		          		})
		          		}
	          			</ul>
	          			
	          			</li>
	          			)
	          		})
	          		}
          			</ul>
          			</p>
          			)
          		}
          		
          		)}
          		</div>
          	  </CardBody>
          	</Card>
		  )}
          	
      </div>
    );
  }
}

Catalog.defaultProps = {
	isWidget: false,
}

Catalog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Catalog);
