import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from 'swiper';
import withStyles from "@material-ui/core/styles/withStyles";
import { infoColor } from "assets/jss/material-dashboard-react.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";

import tasksStyle from "../../assets/jss/material-dashboard-react/components/tasksStyle.jsx";
import { EditArticleLightbox } from "components/Articles/Articles.jsx";


import 'swiper/swiper-bundle.css';
import { postcss } from 'prettier/parser-postcss';

// const imagesStatic = [
//   "https://cdn21vek.by/img/tmp/b/67cb050c242521920х634_Спортивное-начало-недели.jpg",
//   "https://cdn21vek.by/img/tmp/b/67caeed8b2ef41920x634_неделя_FMCG.jpg",
//   "https://cdn21vek.by/img/tmp/b/67ceadac96b031920х634 Садовая краска.jpg",
// ]

const styles = {
  swiperBlock: {
    zIndex: 1,
    // maxWidth: "1090px",
    height: "480px",
    width: "100%",
    margin: "0 auto",
    position: "relative",
  },
  swiperSlide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    borderRadius: "10px",
    overflow: "hidden",
    transition: "box-shadow 0.2s",

  },
  swiperImage: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
  swiperButtonNext: {
    color: infoColor[0] + "!important",
    "&:hover": {
      color: "#0097a7 !important",
    },
  },
  // Стили для кнопки "prev"
  swiperButtonPrev: {
    color: infoColor[0] + "!important",
    "&:hover": {
      color: "#0097a7 !important",
    },
  },
  swiperPaginationBulletActive: {
    backgroundColor: infoColor[0],
  },
  sliderEditWrap: {
    position: "absolute",
    bottom: "0",
    zIndex: "1",
    right: "0",
  }
};


const Slider = ({
  classes,
  images,
  isAdmin,
  isManager,
  i18n,
  editClick
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState();
  const [imagesArr, setImagesArr] = useState([]);

  useEffect(() => {
    if (images) {
      setImagesArr([])
      const textData = images.text;
      const textLine = textData.split("\n");
      textLine.forEach((imageInfo) => {
        const [category, url] = imageInfo.split('|||');
        setImagesArr(prevImages => [...prevImages, { category, url }]);
      })
    } else {
      setImagesArr([])
    }
  }, [images])

  return (
    <div>
      <div className={classes.swiperBlock}>
        <Swiper
          loop={true}
          spaceBetween={10}
          navigation={{
            nextEl: `.${classes.swiperButtonNext}`,
            prevEl: `.${classes.swiperButtonPrev}`,
          }}
          // navigation={true}
          modules={[Autoplay, Thumbs, FreeMode, Navigation, Pagination]}
          thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
          pagination={{
            clickable: true,
            bulletActiveClass: `swiper-pagination-bullet-active ${classes.swiperPaginationBulletActive}`,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          className={classes.swiperSlide}
        >
          {imagesArr && imagesArr.map((item, index) => (
            <Link
              to={`/pricelist?category=${item.category}`}
              style={{ color: "inherit" }}
            >
              <SwiperSlide key={index} className={classes.swiperSlide}>
                <Link
                  to={`/pricelist?category=${item.category}`}
                  style={{ color: "inherit" }}
                >
                  <img src={`${item.url}`} alt={`${item.category}`} className={classes.swiperImage} />
                </Link>
              </SwiperSlide>
            </Link>
          ))}
        </Swiper>
        <div className={`swiper-button-next ${classes.swiperButtonNext}`} />
        <div className={`swiper-button-prev ${classes.swiperButtonPrev}`} />

      </div >
      {isAdmin && (
        <div className={classes.sliderEditWrap}>
          <Tooltip
            id="tooltip-top"
            title={i18n.t('edit')}
          >
            <IconButton
              aria-label="Edit"
              className={classes.tableActionButton}
              onClick={event => editClick(images)}
            >
              <Edit
                className={
                  classes.tableActionButtonIcon + " " + classes.edit
                }
              />
            </IconButton>
          </Tooltip>
        </div>
      )
      }
    </div>
  )
}



export default withStyles({ ...styles, ...tasksStyle })(Slider);