import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import NativeSelect from '@material-ui/core/NativeSelect';
import withStyles from "@material-ui/core/styles/withStyles";

import {
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-react.jsx";

const styles = {
  root: {
    zIndex: 999,
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[6] + " !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor[0]
    }
  },
};

const WarehouseSelect = ({ classes }) => {
  const noSelectWarehouse = "-- Выберите город/склад --";
  const [warehousesList, setWarehousesList] = useState([{ name: noSelectWarehouse, externalId: 'none' }]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(localStorage.getItem('selectedWarehouse') || warehousesList[0].name);

  // const warehousesArr = [
  //   { "id": 2, "supplierId": 16, "name": "Лиговский", "externalId": "ligovsky", "isActive": 1, "userAdd": 22, "dateAdd": "13.02.2025 09:34:54" },
  //   { "id": 3, "supplierId": 16, "name": "Алматы", "externalId": "almaty", "isActive": 1, "userAdd": 22, "dateAdd": "13.02.2025 09:34:54" },
  //   { "id": 4, "supplierId": 16, "name": "Астана", "externalId": "astana", "isActive": 1, "userAdd": 22, "dateAdd": "13.02.2025 09:34:54" }
  // ]
  // localStorage.setItem('supplierWarehouses', JSON.stringify(warehousesArr));


  useEffect(() => {
    try {
      const warehousesListStorage = localStorage.getItem('supplierWarehouses');

      if (warehousesListStorage === null) {
        console.log("Error: No warehouse data found in localStorage.");
        return;
      }

      const ParsedWarehouses = JSON.parse(warehousesListStorage);

      if (ParsedWarehouses) {
        if (selectedWarehouse === noSelectWarehouse) {
          ParsedWarehouses.unshift({ name: noSelectWarehouse, externalId: 'none' });
          setWarehousesList(ParsedWarehouses);
        } else {
          setWarehousesList(ParsedWarehouses);
        }
      }
    } catch (error) {
      console.log("Error: No warehouse data found in localStorage.");
    }
  }, []);

  const handleWarehouseChange = (event) => {
    let newWarehouse = event.target.value;
    if (warehousesList[0].name === noSelectWarehouse) {
      setWarehousesList(warehousesList.filter(item => item.name !== noSelectWarehouse));
    }
    setSelectedWarehouse(newWarehouse);
    localStorage.setItem('selectedWarehouse', newWarehouse);
    
    // this.setState({ state: this.state });
    document.location.href = document.location.href;
  }

  return (
    <FormControl className={classes.formControl} fullWidth>
      <NativeSelect
        id="warehouse"
        onChange={handleWarehouseChange}
        value={selectedWarehouse}
        input={<Input
          classes={{
            root: classes.marginTop,
            disabled: classes.disabled,
            underline: classes.underline
          }}
        />}
      >
        {warehousesList.map((warehouse, index) => (
          (warehouse.isActive == 1 ? [
              <option key={index} value={warehouse.name}>{warehouse.name}</option>
          ] : "" )
        ))}
      </NativeSelect>
    </FormControl>
  )
}

export default withStyles(styles)(WarehouseSelect);