import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import Add from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import customTabsStyle from "assets/jss/material-dashboard-react/components/customTabsStyle.jsx";

class CustomTabs extends React.Component {
  state = {
    value: 0
  };
  
  tabsFiltered = [];
  
  constructor(props) {
    super(props);
    if (this.props.noSelected) {
    	this.state = {
      		value: false,
    	}
  };
  }
  
  handleChange = (event, value) => {
	if (this.props.noSelected) {
		this.setState({ value: value === this.state.value ? false : value });
	} else {
		this.setState({ value })
	}
    if (this.props.getTabActiveId && this.tabsFiltered) {
    	this.props.getTabActiveId(this.tabsFiltered[value].tabId);
    }
  };
  render() {
    const {
      classes,
      headerColor,
      plainTabs,
      tabs,
      title,
      rtlActive,
      isAdmin,
      isManager,
      i18n,
      addClick,
      noSelected
    } = this.props;
    const cardTitle = classNames({
      [classes.cardTitle]: true,
      [classes.cardTitleRTL]: rtlActive
    }); 
    
    
    this.tabsFiltered = tabs.filter(function(item) {
    	return (item !== false ? true : false);
	});
    
    if (this.props.getTabActiveId && this.state.value !== false) {
    	this.props.getTabActiveId(this.tabsFiltered[this.state.value].tabId);
    }
    
    return (
      <Card plain={plainTabs}>
        <CardHeader color={headerColor} plain={plainTabs}
        >
          {title !== undefined ? (
            <div className={cardTitle}>{title}</div>
          ) : null}
          
          <div className={classes.title} style={this.props.noSelected ? {display: "inline-block", width: "100%"} : {display: "inline-block"}}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayNone,
              scrollButtons: classes.scrollButtonGradient,
            }}
            variant={this.props.noSelected ? "scrollable" : "standard"}
            //scrollButtons="auto"
          >
            {this.tabsFiltered.map((prop, key) => {
            
              // if (!prop) {
              //	return "";
              // }
             
              var icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />
                };
              }
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    labelContainer: classes.tabLabelContainer,
                    label: classes.tabLabel,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            })}
          </Tabs>
          </div>
          
          <div className={classes.spacer} style={{display: "inline-block"}} />
      	  
          <div className={classes.actions} style={{float: "right"}}>
      	  	{ (isAdmin) && 
              <Tooltip title={ i18n.t('add') }>
                <IconButton style={{display: "inline-block"}} aria-label="Add" onClick={event => addClick(null)}>
                  <Add />
                </IconButton>
              </Tooltip>
            }
      	  </div>
          
        </CardHeader>
        <CardBody>
          {this.tabsFiltered.map((prop, key) => {
          
            // if (!prop) {
            //  	return "";
            // }
          
          
            if (key === this.state.value) {
              return <div key={key}>{prop.tabContent}</div>;
            }
            return null;
          })}
        </CardBody>
      </Card>
    );
  }
}

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};

export default withStyles(customTabsStyle)(CustomTabs);
