/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import TableList from "views/TableList/TableList";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import SupplierProfile from "views/SupplierProfile/SupplierProfile.jsx";
import SupplierUsers from "views/SupplierUsers/SupplierUsers.jsx";
import Orders from "views/Orders/Orders.jsx";
import Clients from "views/Clients/Clients.jsx";
import Managers from "views/Managers/Managers.jsx";
import Comparator from "components/Table/Comparator.jsx";
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import Cart from "components/Cart/Cart.jsx";
import SupplierSettings from "views/Settings/SupplierSettings.jsx";
import Catalog from "views/Catalog/Catalog.jsx";

import CartScrollBar from "components/Cart/CartScrollBar";
import Counter from "components/Cart//Counter";
import EmptyCart from "components/Cart/EmptyCart";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import Button from "components/CustomButtons/Button.jsx";
import LinearProgress from '@material-ui/core/LinearProgress';

import Drawer from "@material-ui/core/Drawer";
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import IconButton from "@material-ui/core/IconButton";
import i18next from 'i18next';

import routes from "routes.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";

import "assets/jss/material-dashboard-react/components/style.scss";
import "assets/css/styles.css";

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost, getSettingValue, getSettingValueAsBoolean } from "variables/common.js";

import { SnackbarProvider, withSnackbar } from 'notistack';

const styles = theme => ({
	...dashboardStyle(theme),
	danger: {
      backgroundColor: '#d32f2f',
    },
    closeButton: {
    	color: "white",
    	fontSize: "15px"
    },
    content: {
    	padding: "15px 0px 0px 0px",
    	marginTop: "60px",
    	minHeight: "calc(100vh - 95px)"
    }
    
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown",
      openSide: false,
      cart: localStorage.getItem('cart') === null ? [] : JSON.parse(localStorage.getItem('cart')),
      totalItems: 0,
      totalAmount: 0,
      quantity: 1,
      cartBounce: false,
      showProgressBar: false,
      supplier: null,
      user: JSON.parse(localStorage.getItem('user')),
      isAdmin: JSON.parse(localStorage.getItem('user')).value.userRoles.findIndex(x => x.id == 45) !== -1,
      isClientAdmin: (localStorage.getItem('supplierUser') && JSON.parse(localStorage.getItem('supplierUser')).value.isAdmin == 1),
      isManager: (localStorage.getItem('supplierUser') && JSON.parse(localStorage.getItem('supplierUser')).value.isManager == 1),
      isSupervisor: JSON.parse(localStorage.getItem('user')).value.userRoles.findIndex(x => x.id == 2) !== -1,
      supplierId: localStorage.getItem('supplierId'),
      redirectToCheckout: false,
      comparator: localStorage.getItem('comparator') === null ? [] : JSON.parse(localStorage.getItem('comparator')),
      favorites: localStorage.getItem('favorites') === null ? [] : JSON.parse(localStorage.getItem('favorites')),
      modelFromNavbar: localStorage.getItem('modelFromNavbar') === null ? '' : localStorage.getItem('modelFromNavbar'),
    };
  }

  handleProgressBarOpen = () => {
	  
    if (!this.state.showProgressBar) {
    	this.setState({ showProgressBar: true });
    }
//    progressBarCount++;
  };

  handleProgressBarClose = () => {
	  if (this.state.showProgressBar) {
		  this.setState({ showProgressBar: false });
	  }
  };
  
  handleShowSnackbar = (message, variant, cb) => {
	var that = this;
    this.props.enqueueSnackbar(message, { variant: (variant ? variant : 'info'), autoHideDuration: 4000,
    action: key => (
	        <IconButton onClick={() => this.props.closeSnackbar(key)}>
	            ✖
	        </IconButton>
	), 
	onEntered: function() {if (cb !== undefined && cb != null) {cb(message);}}
    });
//    if (cb !== undefined && cb != null) {
//		cb(message);
//	}
  };

  handleCategory(event) {
    this.setState({ category: event.target.value });
  }

  handleAddToCart = (item) => {
    this.setState({
      quantity: document.getElementById("quantity-undefined") !== null ? Number(document.getElementById("quantity-undefined").value) : Number(document.getElementById("quantity-" + item.id).value)
    }, () => {
      
      // localStorage.setItem('selectedWarehouse', 'Пролетарская'); // ! 
      
      if (getSettingValueAsBoolean('WAREHOUSE_IS_MANDATORY', false)) {
      	var selectedWarehouse = localStorage.getItem('selectedWarehouse');
      	if (!selectedWarehouse) {
      		this.handleShowSnackbar('Пожалуйста, выберите сначала город/склад');
      		return;
      	}
      	if (!item.warehousesText) {
      		this.handleShowSnackbar('Нет информации о складе у данного товара');
      		return;
      	}
      	if (item.warehousesText != selectedWarehouse) {
      		this.handleShowSnackbar('В корзину можно добавлять только товары со склада ' + selectedWarehouse, 'error');
      		return;
      	}
      	//alert(item.warehousesText);
      }
      
      let cartItems = (this.state.cart ? this.state.cart : []);
      let productID = item.id;
      if (this.checkProduct(productID)) {
        let index = cartItems.findIndex(x => x.id == productID);
        cartItems[index].cartQuantity =
          Number(cartItems[index].cartQuantity) + this.state.quantity;
        this.setState({
          cart: cartItems
        });
      } else {
        item.cartQuantity = this.state.quantity;
        item.cartDateAdd = new Date();
        cartItems.push(item);
      }
      this.setState({
        cart: cartItems
      });
      this.sumTotalItems(this.state.cart);
      this.sumTotalAmount(this.state.cart);
      
      localStorage.setItem('cart', JSON.stringify(this.state.cart)); 
      
    });


  }

  handleToggleComparableItem = (event, item) => {

    const { comparator } = this.state;
    const index = comparator.findIndex(x => x.id == item.targetPortalItemId);
    let newComparator = [];

    if (index === -1) {
      var that = this;

      var cb = function(data) { 
        data.splr = item;
        newComparator = newComparator.concat(comparator, data);
        that.setState({ comparator: newComparator });
        
        localStorage.setItem('comparator', JSON.stringify(newComparator)); 
      };
      
      var user = JSON.parse(localStorage.getItem('user')).value;

      doAjax("https://api.zoomos.by/item/" + item.targetPortalItemId + "?key=b2b-nnF7C8DxD" + "&principalId=" + user.id, cb);

    } else {
      if (index === 0) {
        newComparator = newComparator.concat(comparator.slice(1));
      } else if (index === comparator.length - 1) {
        newComparator = newComparator.concat(comparator.slice(0, -1));
      } else if (index > 0) {
        newComparator = newComparator.concat(
          comparator.slice(0, index),
          comparator.slice(index + 1),
        );
      }
      this.setState({ comparator: newComparator });
      
      localStorage.setItem('comparator', JSON.stringify(newComparator)); 
    }

    

  }

  handleToggleFavorite = (event, id) => {

    const { favorites } = this.state;
    const index = favorites.indexOf(id);
    let newFavorites = [];

    if (index === -1) {
      newFavorites = newFavorites.concat(favorites, id);
    } else if (index === 0) {
      newFavorites = newFavorites.concat(favorites.slice(1));
    } else if (index === favorites.length - 1) {
      newFavorites = newFavorites.concat(favorites.slice(0, -1));
    } else if (index > 0) {
      newFavorites = newFavorites.concat(
        favorites.slice(0, index),
        favorites.slice(index + 1),
      );
    }
    this.setState({ favorites: newFavorites });
    
    localStorage.setItem('favorites', JSON.stringify(newFavorites));
    
  }

  handleSetModelFromNavbar = model => {
    this.setState({ modelFromNavbar: model });
  }

  handleRemoveProduct = (id, e) => {
    let cart = this.state.cart;
    if (!cart) {
    	return;
    }
    let index = cart.findIndex(x => x.id == id);
    cart.splice(index, 1);
    this.setState({
      cart: cart
    });
    this.sumTotalItems(this.state.cart);
    this.sumTotalAmount(this.state.cart);
    e.preventDefault();
  }

  resetCart = () => {
    this.setState({ cart: [], totalAmount: 0, totalItems: 0 });
    localStorage.setItem('cart', null); 
  }

  checkProduct = productID => {
    let cart = (this.state.cart ? this.state.cart : []);
    return cart.some(function(item) {
      return item.id === productID;
    });
  }
  sumTotalItems = () => {
    let total = 0;
    let cart = this.state.cart;
//    total = cart ? cart.length : 0;
    
    for (var i = 0; i < (cart ? cart.length : 0); i++) {
      total += parseInt(cart[i].cartQuantity);
    }
    
    this.setState({
      totalItems: total
    });
  }
  sumTotalAmount = () => {
    let total = 0;
    let cart = this.state.cart;
    for (var i = 0; i < (cart ? cart.length : 0); i++) {
      total += cart[i].price * parseInt(cart[i].cartQuantity);
    }
    this.setState({
      totalAmount: total.toFixed(2)
    });
  }

  updateQuantity = (quantity, id) => {
    this.setState({
      quantity: quantity
    }, () => { if (id != null) this.updateItemQuantity(id) });
  }

  updateItemQuantity = id => {
    let cartItems = this.state.cart;
    let index = cartItems.findIndex(x => x.id == id);
    cartItems[index].cartQuantity = this.state.quantity;
    this.setState({cart: cartItems});
    this.sumTotalItems(this.state.cart);
    this.sumTotalAmount(this.state.cart);
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
	  
	console.log('handleDrawerToggle');
    this.setState({ openSide: !this.state.openSide });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ openSide: false });
    }
  };

  componentDidMount = () => {
    if (navigator.platform.indexOf("Win") > -1) {
      console.log("this.refs.mainPanel = " + this.refs.mainPanel);
      if (this.refs.mainPanel) {
        const ps = new PerfectScrollbar(this.refs.mainPanel);
      }
    }
    window.addEventListener("resize", this.resizeFunction);
    this.sumTotalItems(this.state.cart);
    this.sumTotalAmount(this.state.cart);

    window.addEventListener('beforeunload', () => { 
      localStorage.setItem('cart', JSON.stringify(this.state.cart)); 
      localStorage.setItem('comparator', JSON.stringify(this.state.comparator)); 
      localStorage.setItem('favorites', JSON.stringify(this.state.favorites));
    });
    
    if (localStorage.getItem('supplierUser')) {
    	var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;
	    if (false && supplierUser.supplierClient && supplierUser.supplierClient.supplierManager) {
	    
	    	var managerHtml = 'Ваш менеджер' + ':<br/>';
	    	
	    	if (supplierUser.supplierClient.supplierManager.name) {
	    		managerHtml += supplierUser.supplierClient.supplierManager.name + "<br/>";
	    	}
	    	if (supplierUser.supplierClient.supplierManager.phone) {
	    		managerHtml += 'тел.: ' + supplierUser.supplierClient.supplierManager.phone + "<br/>";
	    	}
	    	if (supplierUser.supplierClient.supplierManager.email) {
	    		managerHtml += 'email: ' + supplierUser.supplierClient.supplierManager.email;
	    	}
	    
	    	document.getElementById("manager-info").innerHTML = managerHtml;
	    }
	    if (supplierUser.supplierClient && supplierUser.supplierClient.supplierManagerUser && supplierUser.supplierClient.supplierManagerUser.user) {
		    
	    	var managerHtml = 'Ваш менеджер' + ':<br/>';
	    	
	    	if (supplierUser.supplierClient.supplierManagerUser.user.firstName) {
	    		managerHtml += supplierUser.supplierClient.supplierManagerUser.user.firstName + "<br/>";
	    	}
	    	if (supplierUser.supplierClient.supplierManagerUser.user.userContacts && supplierUser.supplierClient.supplierManagerUser.user.userContacts.skype) {
	    		managerHtml += 'skype: ' + supplierUser.supplierClient.supplierManagerUser.user.userContacts.skype + "<br/>";
	    	}
	    	if (supplierUser.supplierClient.supplierManagerUser.user.userContacts && supplierUser.supplierClient.supplierManagerUser.user.userContacts.phoneWork) {
	    		managerHtml += 'тел.: ' + supplierUser.supplierClient.supplierManagerUser.user.userContacts.phoneWork + "<br/>";
	    	}
	    	if (supplierUser.supplierClient.supplierManagerUser.user.email) {
	    		managerHtml += 'email: ' + supplierUser.supplierClient.supplierManagerUser.user.email;
	    	}
	    
	    	if (document.getElementById("manager-info")) {
	    		document.getElementById("manager-info").innerHTML = managerHtml;
	    	}
	    }
	}
  }

  componentWillUpdate = e => {

    if (e.history.location.pathname !== this.props.location.pathname) { //e.location.pathname
      if (this.refs.mainPanel) {	
    	  this.refs.mainPanel.scrollTop = 0;
      }
      this.handleProgressBarClose();
      if (this.state.openSide) {
        this.setState({ openSide: false });
      }
    }
  }
  
  componentDidUpdate(prevProps) {
	if (this.props.location.pathname !== prevProps.location.pathname) {
	  window.scrollTo(0, 0);
	  //this.handleProgressBarClose();
	  if (this.state.openSide) {
	    this.setState({ openSide: false });
	  }
	}
  }
  
  componentWillUnmount = event => {
    window.removeEventListener("resize", this.resizeFunction);
  }

  componentWillMount = () => {
    this.setLanguage('ru');
    
    var localStorageApiKey = localStorage.getItem('apiKey');
    if (localStorageApiKey) {
    	constants.apiKey = localStorageApiKey;
    }
    
    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierId=" + this.state.supplierId;

    var that = this;

    var cb = function(data) { 
    	that.setState({ supplier: data }, () => { document.title = document.title + " - " + data.name });
    	if (data.supplierSettings) {
			localStorage.setItem('supplierSettings', JSON.stringify(data.supplierSettings));
		}
		if (data.supplierWarehouses) {
			localStorage.setItem('supplierWarehouses', JSON.stringify(data.supplierWarehouses));
		}
   	};
   	
    doAjax(url, cb);
  }

  setLanguage = language => {
    i18next.init({
      lng: language,
      resources: require(`./i18n/${language}.json`)
    });
    this.forceUpdate()
  }

  handleCheckoutOpen = event => {
    this.setState({
      redirectToCheckout: true
    })
  }

  handleCheckoutClose = event => {
    this.setState({
      redirectToCheckout: false
    })
  }

  render() {
    const { classes, ...rest } = this.props;

    var user = JSON.parse(localStorage.getItem('user'));
    
    if (!user) {
    	return <Redirect to='/login'/>
    }

   	if (this.props.location.pathname == '/') {
   		return <Redirect to='/dashboard'/>
   	}

    if (user.timestamp !== null) {   
      if (user.timestamp + 60 * 60 * 1000 <= new Date().getTime() || this.state.supplierId === null) {
        console.log("clear storage");
        //localStorage.clear();
        localStorage.removeItem('supplierId');
        localStorage.removeItem('user');
        localStorage.removeItem('supplierUser');
        localStorage.removeItem('supplierClient');
        localStorage.removeItem('supplierSettings');
        localStorage.removeItem('modelFromNavbar');
        
//        localStorage.setItem('cart', JSON.stringify(this.state.cart)); 
//        localStorage.setItem('comparator', JSON.stringify(this.state.comparator)); 
//        localStorage.setItem('favorites', JSON.stringify(this.state.favorites));
        
        
        return <Redirect to='/login'/>
      }

      user.timestamp = new Date().getTime();
      localStorage.setItem('user', JSON.stringify(user));
    }

    const switchRoutes = (
      <Switch>
        {routes.map((prop, key) => {
          //if (prop.layout === "") 
            {
            if (prop.path === "/pricelist") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={
                    props => 
                      <TableList
                        key={2}
                        fav={false}
                        supplierId={this.state.supplierId}
                        supplierUserId={localStorage.getItem('supplierUser') === null ? null : JSON.parse(localStorage.getItem('supplierUser')).value.id}
                        supplierClientId={localStorage.getItem('supplierUser') === null ? null : JSON.parse(localStorage.getItem('supplierUser')).value.supplierClientId}
                        cart={this.state.cart} 
                        removeProduct={this.handleRemoveProduct} 
                        addToCart={this.handleAddToCart} 
                        updateQuantity={this.updateQuantity}
                        updateItemQuantity={this.updateItemQuantity}
                        productQuantity={this.state.quantity} 
                        i18n={i18next} 
                        handleProgressBarOpen={this.handleProgressBarOpen} 
                        handleProgressBarClose={this.handleProgressBarClose}
                        handleShowSnackbar={this.handleShowSnackbar}
                        totalAmount={this.state.totalAmount}
                        comparator={this.state.comparator}
                        favorites={this.state.favorites}
                        toggleComparableItem={this.handleToggleComparableItem}
                        toggleFavorite={this.handleToggleFavorite}
                        isAdmin={this.state.isAdmin}
                        isManager={this.state.isManager}
                        isSupervisor={this.state.isSupervisor}
                        modelFromNavbar={this.state.modelFromNavbar}
                      />
                  }
                />
              );
            } else if (prop.path === "/cart") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={
                    props => 
                      <Cart
                        i18n={i18next}
                        items={this.state.cart}
                        removeProduct={this.handleRemoveProduct}
                        updateQuantity={this.updateQuantity}
                        updateItemQuantity={this.updateItemQuantity}
                        totalAmount={this.state.totalAmount}
                        handleProgressBarOpen={this.handleProgressBarOpen}
                        handleProgressBarClose={this.handleProgressBarClose}
                        handleShowSnackbar={this.handleShowSnackbar}
                        supplierId={this.state.supplierId}
                        resetCart={this.resetCart}
                      />
                  }
                />
              );
            } else if (prop.path === "/settings") {
                return (
                        <Route
                          path={prop.layout + prop.path}
                          render={
                            props => 
                              <SupplierSettings
                                i18n={i18next}
                                handleProgressBarOpen={this.handleProgressBarOpen} 
                                handleProgressBarClose={this.handleProgressBarClose}
                                handleShowSnackbar={this.handleShowSnackbar}
                                classes={classes}
                                supplierId={this.state.supplierId}
                                isAdmin={this.state.isAdmin}
                                isClientAdmin={this.state.isClientAdmin}
                                isSupervisor={this.state.isSupervisor}
                              />
                          }
                        />
                      );
            } else if (prop.path === "/catalog") {
                return (
                        <Route
                          path={prop.layout + prop.path}
                          render={
                            props => 
                              <Catalog
                                i18n={i18next}
                                handleProgressBarOpen={this.handleProgressBarOpen} 
                                handleProgressBarClose={this.handleProgressBarClose}
                                handleShowSnackbar={this.handleShowSnackbar}
                                classes={classes}
                                supplierId={this.state.supplierId}
                                isAdmin={this.state.isAdmin}
                                isClientAdmin={this.state.isClientAdmin}
                                isSupervisor={this.state.isSupervisor}
                              />
                          }
                        />
                      );
            }
            else if (prop.path === "/user") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={
                    props => 
                      <UserProfile 
                        i18n={i18next}
                        handleProgressBarOpen={this.handleProgressBarOpen} 
                        handleProgressBarClose={this.handleProgressBarClose}
                        handleShowSnackbar={this.handleShowSnackbar}
                        classes={classes}
                        user={this.state.user.value}
                      />
                  }
                />
              );
            } else if (prop.path === "/supplier") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={
                    props => 
                      <SupplierProfile 
                        i18n={i18next}
                        handleProgressBarOpen={this.handleProgressBarOpen} 
                        handleProgressBarClose={this.handleProgressBarClose}
                        handleShowSnackbar={this.handleShowSnackbar}
                        classes={classes}
                        supplierId={this.state.supplierId}
                        isAdmin={this.state.isAdmin} 
                      />
                  }
                />
              );
            } else if (prop.path === "/clients") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={
                    props => 
                      <Clients 
                        i18n={i18next}
                        handleProgressBarOpen={this.handleProgressBarOpen} 
                        handleProgressBarClose={this.handleProgressBarClose}
                        handleShowSnackbar={this.handleShowSnackbar}
                        classes={classes}
                        user={this.state.user.value} 
                        supplierId={this.state.supplierId} 
                        isAdmin={this.state.isAdmin}
                        isManager={this.state.isManager}
                      />
                  }
                />
              );
            } else if (prop.path === "/managers") {
                return (
                  <Route
                    path={prop.layout + prop.path}
                    render={
                      props => 
                        <Managers 
                          i18n={i18next}
                          handleProgressBarOpen={this.handleProgressBarOpen} 
                          handleProgressBarClose={this.handleProgressBarClose}
                          handleShowSnackbar={this.handleShowSnackbar}
                          classes={classes}
                          user={this.state.user.value} 
                          supplierId={this.state.supplierId} 
                          isAdmin={this.state.isAdmin}
                        />
                    }
                  />
                );  
            } else if (prop.path === "/users") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={
                    props => 
                      <SupplierUsers 
                        i18n={i18next}
                        handleProgressBarOpen={this.handleProgressBarOpen} 
                        handleProgressBarClose={this.handleProgressBarClose}
                        handleShowSnackbar={this.handleShowSnackbar}
                        classes={classes}
                        supplierId={this.state.supplierId}
                        isAdmin={this.state.isAdmin} 
                        isManager={this.state.isManager}
                        isClientAdmin={this.state.isClientAdmin}
                        isSupervisor={this.state.isSupervisor}
                        supplierClientId={localStorage.getItem('supplierUser') === null ? null : JSON.parse(localStorage.getItem('supplierUser')).value.supplierClientId}
                      />
                  }
                />
              );
            } else if (prop.path === "/orders") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={
                    props => 
                      <Orders 
                        i18n={i18next}
                        handleProgressBarOpen={this.handleProgressBarOpen} 
                        handleProgressBarClose={this.handleProgressBarClose}
                        handleShowSnackbar={this.handleShowSnackbar}
                        classes={classes}
                        user={this.state.user.value} 
                        supplierId={this.state.supplierId} 
                        isAdmin={this.state.isAdmin}
                        isManager={this.state.isManager}
                        isClientAdmin={this.state.isClientAdmin}
                        isSupervisor={this.state.isSupervisor}
                        supplierClientId={localStorage.getItem('supplierUser') === null ? null : JSON.parse(localStorage.getItem('supplierUser')).value.supplierClientId}
                      />
                  }
                />
              );
            } else if (prop.path === "/comparator") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={
                    props => 
                      <Comparator
                        handleComparatorClose={this.handleComparatorClose}
                        i18n={i18next}
                        items={this.state.comparator}
                        toggleComparableItem={this.handleToggleComparableItem}
                        addToCart={this.handleAddToCart}
                        updateQuantity={this.updateQuantity}
                        productQuantity={this.state.productQuantity}
                      />
                  }
                />
              );
            } else if (prop.path === "/dashboard") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={
                    props => 
                      <DashboardPage
                        i18n={i18next}
                        handleProgressBarOpen={this.handleProgressBarOpen} 
                        handleProgressBarClose={this.handleProgressBarClose}
                        handleShowSnackbar={this.handleShowSnackbar}
                        classes={classes}
                        userId={this.state.user.value.id}
                        isAdmin={this.state.isAdmin}
                        supplierId={this.state.supplierId} 
                      />
                  }
                />
              );
            } else if (prop.path === "/favorites") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={
                    props => 
                      <TableList
                        key={1}
                        fav={true}
                        supplierId={this.state.supplierId} 
			            supplierUserId={localStorage.getItem('supplierUser') === null ? null : JSON.parse(localStorage.getItem('supplierUser')).value.id}
			            supplierClientId={localStorage.getItem('supplierUser') === null ? null : JSON.parse(localStorage.getItem('supplierUser')).value.supplierClientId}
                        cart={this.state.cart} 
                        removeProduct={this.handleRemoveProduct} 
                        addToCart={this.handleAddToCart} 
                        updateQuantity={this.updateQuantity}
                        updateItemQuantity={this.updateItemQuantity}
                        productQuantity={this.state.quantity} 
                        i18n={i18next} 
                        handleProgressBarOpen={this.handleProgressBarOpen} 
                        handleProgressBarClose={this.handleProgressBarClose}
                        handleShowSnackbar={this.handleShowSnackbar}
                        totalAmount={this.state.totalAmount}
                        comparator={this.state.comparator}
                        favorites={this.state.favorites}
                        toggleComparableItem={this.handleToggleComparableItem}
                        toggleFavorite={this.handleToggleFavorite}
                        modelFromNavbar={this.state.modelFromNavbar}
                      />
                  }
                />
              );
            } else {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
          }
        })
      }
      </Switch>
    );

    return (
      <div className={classes.wrapper}>

        <Sidebar
          routes={routes}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.openSide}
          color={this.state.color}
          i18n={i18next}
          supplier={this.state.supplier}
          supplierId={this.state.supplierId}
          isAdmin={this.state.isAdmin}
          isManager={this.state.isManager}
          isClientAdmin={this.state.isClientAdmin}
          isSupervisor={this.state.isSupervisor}
          {...rest}
        />
        <div className={classNames(classes.mainPanel, this.state.openSide && classes.mainPanelShift)} ref="mainPanel">
          <div className={classes.progressBar}>
            { this.state.showProgressBar ? <LinearProgress query style={{zIndex: 1500}} /> : null }
          </div>
         <Navbar
              routes={routes}
              handleDrawerToggle={this.handleDrawerToggle}
              openSide={this.state.openSide}
              cart={this.state.cart}
              removeProduct={this.handleRemoveProduct}
              showCart={this.state.showCart}
              handleCart={this.handleCart}
              totalItems={this.state.totalItems}
              totalAmount={this.state.totalAmount}
              i18n={i18next}
              setLanguage={this.setLanguage}
              supplier={this.state.supplier}
              supplierId={this.state.supplierId}
              handleCheckoutOpen={this.handleCheckoutOpen}
              totalItemsToCompare={this.state.comparator.length}
              handleComparatorOpen={this.handleComparatorOpen}
              handleFavoritesOpen={this.handleFavoritesOpen}
              totalFavorites={this.state.favorites.length}
              setModelFromNavbar={this.handleSetModelFromNavbar}
              {...rest}
            />  

          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {this.getRoute() ? <Footer /> : null}
        </div>
        
        <div><img src="https://mc.yandex.ru/watch/55375996" style={{position: "absolute", left: "-9999px"}} alt="" /></div>
        
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

const DashboardWithSnackbar = withSnackbar(Dashboard);

function DashboardInit(props) {
	const { classes, ...rest } = props;
	return (
		<SnackbarProvider 
			maxSnack={3}
			anchorOrigin={{
		        vertical: 'top',
		        horizontal: 'right',
	    	}}
	    	classes={{
        		variantWarning: classes.warning,
        	}}
	    >
			<DashboardWithSnackbar classes={classes} { ...rest }/>
		</SnackbarProvider>
	);
}

export default withStyles(styles)(DashboardInit);
