import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

import Downshift from 'downshift';
import * as constants from "variables/constants.jsx";
import { doAjax } from "variables/common.js";
import MenuItem from '@material-ui/core/MenuItem';

import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  tableWrapper: {
    "& > table > tbody": {
      overflow: "auto",
      maxHeight: "60vh"
    },
    "& > table td": {
      paddingLeft: "10px",
      paddingRight: "15px"
    },
    "& > table td:nth-child(1)": {
      paddingLeft: "24px",
    },
    "& > table th": {
      paddingLeft: "10px",
      paddingRight: "15px"
    },
    "& > table th:nth-child(1)": {
      paddingLeft: "24px",
    },
    "& > table td:last-child": {
      paddingRight: "15px",
      maxWidth: "80px",
    },
    maxHeight: "calc(100vh - 60px - 64px - 56px - 74px)",
    overflow: "auto",
  },
  editButton: {
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    height: "21px",
    minWidth: "1px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  editIcon: {
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    height: "21px",
    minWidth: "21px",
    color: "#848484",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}

const BannerEditor = ({ i18n, classes, handleProgressBarOpen, handleProgressBarClose, handleShowSnackbar, value, saveContent, setValue }) => {
  const [imagesArr, setImagesArr] = useState([])
  const [categorySuggestions, setCategorySuggestions] = useState([])
  const [urlSuggestions, setUrlSuggestions] = useState('')
  const [imagesString, setImagesString] = useState()

  const [categoryInput, setCategoryInput] = useState('');

  const handleCategoryChange = event => {

    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/category/search?key=' + constants.apiKey + "&principalId=" + user.id + "&supplierId=" + localStorage.getItem('supplierId') + '&upd=' + new Date().getTime() + "&s=" + document.getElementById('category-options-input').value;

    var cb = function (data) {
      setCategorySuggestions(data);
    }
    handleProgressBarOpen();
    doAjax(url, cb, handleProgressBarClose, handleShowSnackbar);
  }

  function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
  }

  function getSuggestions(value, { showEmpty = false } = {}, suggestions) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0 && !showEmpty
      ? []
      : suggestions;
  }

  function renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
          fontSize: "14px"
        }}
      >
        <span style={{ marginLeft: "0px" }}>{suggestion}</span>
      </MenuItem>
    );
  }

  const handleAddImageURLClick = (event) => {

    var category = document.getElementById('category-options-input').value;

    if (urlSuggestions == null || urlSuggestions == 0) {
      alert("Пожалуйста, заполните ссылку");
      return;
    }

    if (category == null || category == 0) {
      alert("Пожалуйста, заполните категорию");
      return;
    }

    setImagesArr(prevImages => {
      const updatedImages = [...prevImages, { category, url: urlSuggestions }]

      const result = updatedImages.map(item => `${item.category}|||${item.url}`).join("\n");

      //setValue(result);
      saveContent(result);

      return updatedImages;
    })

    setUrlSuggestions('');
  }

  const handleDeleteImage = (index) => {
    if (!window.confirm("Удалить ссылку?")) {
      return;
    }
    setImagesArr(prevImage => {
      const updatedImages = prevImage.filter((_, i) => i != index);

      const result = updatedImages.map(item => `${item.category}|||${item.url}`).join("\n");
      saveContent(result);

      return updatedImages;
    })
  }


  useEffect(() => {
    if (value) {
      setImagesArr([])
      const textLine = value.split("\n");
      textLine.forEach((imageInfo) => {
        const [category, url] = imageInfo.split('|||');
        setImagesArr(prevImages => [...prevImages, { category, url }]);
      })
    } else {
      setImagesArr([])
    }
  }, [value])

  return (
    <div style={{ marginTop: "25px" }}>
      <>
        <Downshift id="url-options" style={{ width: "60%" }} initialIsOpen={true} initialInputValue={""} onSelect={selection => {
          if (selection !== null) {
          }
          setUrlSuggestions('');
        }}>
          {({
            getInputProps,
            getLabelProps,
          }) => (
            <div className={classes.container} style={{ width: "60%", marginLeft: "0px", display: "inline-block" }}>
              {renderInput({
                fullWidth: true,
                classes,
                label: i18n.t('url'),
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: {
                  ...getInputProps({
                    value: urlSuggestions,
                    onChange: (event) => {
                      if (event?.target?.value !== undefined) {
                        setUrlSuggestions(event.target.value);
                      }
                    },
                  }),
                },
              })}

            </div>
          )
          }
        </Downshift>
        <Downshift id="category-options" style={{ width: "20%" }} initialIsOpen={true} initialInputValue={""} onSelect={selection => {
          if (selection !== null) {
            // setCategoryInput(selection);
          }
          setCategorySuggestions([]);
        }}>
          {({
            clearSelection,
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            openMenu,
            selectedItem,
          }) => {
            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
              onChange: event => {
                if (event.target.value === '') {
                  clearSelection();
                } else {
                  handleCategoryChange(event);
                }
                {/* setCategoryInput(event.target.value); */ }
              },
              onFocus: openMenu,
            });

            return (
              <div className={classes.container} style={{ width: "20%", marginLeft: "15px", display: "inline-block" }}>
                {renderInput({
                  fullWidth: true,
                  classes,
                  label: i18n.t('category'),
                  InputLabelProps: getLabelProps({ shrink: true }),
                  InputProps: { onBlur, onChange, onFocus },
                  inputProps,
                })}
                {/* inputProps: {
                      ...inputProps,
                      value: categoryInput,
                    }, */}

                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper className={classes.paper} square style={{ maxHeight: "200px", overflow: "auto", position: "absolute", zIndex: "100", width: "300px" }}>
                      {getSuggestions(inputValue, { showEmpty: false }, categorySuggestions).map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: suggestion }),
                          highlightedIndex,
                          selectedItem,
                        }),
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            );
          }}
        </Downshift>
        <Button style={{ marginLeft: "20px", verticalAlign: "bottom", marginBottom: "4px" }}
          onClick={event => handleAddImageURLClick(event)}
          color="inherit"
          disableRipple
          className={classes.editButton}
        >
          {i18n.t('add')}
        </Button>

      </>

      <div className={classes.tableWrapper} style={{ maxHeight: "400px", marginTop: "35px", marginBottom: "50px" }}>

        <Table className={classNames()} size={'small'}>
          <TableBody>
            <TableRow >
              <TableCell style={{ width: "70%", paddingLeft: "0px" }}>
                Ссылка
              </TableCell>
              <TableCell style={{ width: "20%" }}>
                Категория
              </TableCell>
              <TableCell style={{ width: "10%" }}>

              </TableCell>
            </TableRow>
            {imagesArr && imagesArr.map((imageLine, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell style={{ width: "70%", paddingLeft: "0px" }}>
                    {imageLine.url}
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    {imageLine.category}
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>
                    <Button
                      onClick={() => handleDeleteImage(index)}
                      color="inherit"
                      disableRipple
                      className={classes.editButton}
                    >
                      <Close className={classes.editIcon} />
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>



    </div >
  )
}



export default withStyles(styles)(BannerEditor);